import { Header } from '../features/header';
import { SignIn, AwaitSiwe } from '../features/sign-in';
import { NoCreepKid } from '../features/web3/eth/no-creep-kid';
import { GenerateImages } from '../features/generate/generate-images';
import { useAccount } from 'wagmi';
import { useAppSelector } from '../app/hooks';
import { selectIsCkHolder } from '../features/web3/eth/eth-slice';
import { selectSiweIsLoggedIn } from '../features/auth/siwe-auth/siwe-auth-slice';
import {
  selectGauthIsLoggedIn,
  selectGauthStatus,
} from '../features/auth/gauth/gauth-slice';
import { NoGauth } from '../features/auth/gauth/NoGauth';

export const Generate = () => {
  const { isConnected } = useAccount();
  const isCkHolder = useAppSelector(selectIsCkHolder);
  const isSiweLoggedIn = useAppSelector(selectSiweIsLoggedIn);
  const isGauthLoggedIn = useAppSelector(selectGauthIsLoggedIn);
  const gauthStatus = useAppSelector(selectGauthStatus);
  const gauthFailed = gauthStatus === 'failed';

  return (
    <>
      {!isSiweLoggedIn && !isGauthLoggedIn && <Header />}
      {!isConnected && !isSiweLoggedIn && !isGauthLoggedIn && !gauthFailed && (
        <SignIn />
      )}
      {isConnected && isCkHolder && !isSiweLoggedIn && !isGauthLoggedIn && (
        <AwaitSiwe />
      )}
      {isConnected && !isCkHolder && <NoCreepKid />}
      {!isSiweLoggedIn && gauthFailed && <NoGauth />}
      {(isGauthLoggedIn || (isSiweLoggedIn && isCkHolder)) && (
        <GenerateImages />
      )}
    </>
  );
};
