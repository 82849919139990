import { get } from 'lodash';
import { Direction } from '../app/types/instrument';
import {
  ModifiedSettings,
  SettingsAction,
  SettingsActions,
  SettingsChanges,
  defaultDirectionValues,
  getSettingValue,
  getSettingsDiff,
} from '../utils';
import { Textarea } from 'flowbite-react';

interface DirectionConfigLiteProps {
  loadoutId: string;
  loadoutName: string;
  directions: Direction[];
  onSlotSelect: (slot: number) => void;
  selectedSlot: number;
  onSettingsChange: (settings: ModifiedSettings) => void;
  modifiedSettings: ModifiedSettings;
}

const directionColor = 'bg-[#DFE0E2]';

export const DirectionConfigLite = ({
  loadoutId,
  loadoutName,
  directions = [],
  onSlotSelect,
  selectedSlot,
  onSettingsChange,
  modifiedSettings,
}: DirectionConfigLiteProps) => {
  const directionSelected = selectedSlot > -1;
  const selectedDirection = directionSelected
    ? get(directions, `[${selectedSlot}]`, {})
    : ({} as Direction);

  const handleClear = (toDefault: boolean, directionIds: string[]) => {
    defaultDirectionValues.forEach((direction, index) => {
      // label
      onSettingsChange(
        getSettingsDiff(undefined, modifiedSettings, {
          action: SettingsActions.update as SettingsAction,
          change: {
            key: SettingsChanges.direction,
            value: {
              loadoutId: loadoutId,
              id: directionIds[index],
              update: toDefault ? direction.label : '',
            },
          },
        }),
      );
      // context
      onSettingsChange(
        getSettingsDiff(undefined, modifiedSettings, {
          action: SettingsActions.update as SettingsAction,
          change: {
            key: SettingsChanges.direction,
            value: {
              loadoutId: loadoutId,
              id: directionIds[index],
              update: toDefault ? direction.context : '',
              context: true,
            },
          },
        }),
      );
      // func
      onSettingsChange(
        getSettingsDiff(undefined, modifiedSettings, {
          action: SettingsActions.update as SettingsAction,
          change: {
            key: SettingsChanges.direction,
            value: {
              loadoutId: loadoutId,
              id: directionIds[index],
              update: toDefault ? direction.func : '',
              func: true,
            },
          },
        }),
      );
    });
  };

  const loadoutSelected = selectedSlot === -1;

  return (
    <div>
      <div
        className={`w-full p-4 rounded-2xl mb-4 bg-slate-400 cursor-pointer ${
          loadoutSelected ? 'outline outline-4 outline-white' : ''
        }`}
        onClick={() => {
          if (!loadoutSelected) {
            onSlotSelect(-1);
          }
        }}
      >
        <div className="grid grid-cols-3 gap-3">
          {directions.map((direction, index) => (
            <div
              className={`flex ${directionColor} justify-center items-center h-[3.5rem] rounded-2xl cursor-pointer text-black px-2 ${
                (selectedDirection || {}).id === direction.id
                  ? 'outline outline-4 outline-white'
                  : ''
              }`}
              onClick={(e) => {
                e.stopPropagation();
                if ((selectedDirection || {}).id === direction.id) {
                  onSlotSelect(-1);
                } else {
                  onSlotSelect(index);
                }
              }}
              key={`instrument-direction-${direction.id || index}`}
            >
              <span className="truncate select-none">
                {getSettingValue(
                  modifiedSettings,
                  `instrument.loadouts.${loadoutId}.directions.${direction.id}.label`,
                  direction.label,
                )}
              </span>
            </div>
          ))}
        </div>
      </div>
      {directionSelected && (
        <>
          <label
            htmlFor="label"
            className="block text-sm font-medium leading-6 text-white"
          >
            Label
          </label>
          <input
            name="label"
            id="label"
            className="block w-full rounded-md border-0 p-2 mb-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={getSettingValue(
              modifiedSettings,
              `instrument.loadouts.${loadoutId}.directions.${selectedDirection.id}.label`,
              selectedDirection.label,
            )}
            placeholder="Please enter a label..."
            onChange={(e) => {
              onSettingsChange(
                getSettingsDiff(undefined, modifiedSettings, {
                  action: SettingsActions.update as SettingsAction,
                  change: {
                    key: SettingsChanges.direction,
                    value: {
                      loadoutId: loadoutId,
                      id: selectedDirection.id,
                      update: e.target.value,
                    },
                  },
                }),
              );
            }}
          />
          <label
            htmlFor="context"
            className="block text-sm font-medium leading-6 text-white"
          >
            Context
          </label>
          <input
            name="context"
            id="context"
            className="block w-full rounded-md border-0 p-2 mb-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={getSettingValue(
              modifiedSettings,
              `instrument.loadouts.${loadoutId}.directions.${selectedDirection.id}.context`,
              selectedDirection.context,
            )}
            placeholder="Please enter a context..."
            onChange={(e) => {
              onSettingsChange(
                getSettingsDiff(undefined, modifiedSettings, {
                  action: SettingsActions.update as SettingsAction,
                  change: {
                    key: SettingsChanges.direction,
                    value: {
                      loadoutId: loadoutId,
                      id: selectedDirection.id,
                      update: e.target.value,
                      context: true,
                    },
                  },
                }),
              );
            }}
          />
          <label
            htmlFor="function"
            className="block text-sm font-medium leading-6 text-white"
          >
            Function
          </label>
          <Textarea
            name="func"
            id="func"
            className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={getSettingValue(
              modifiedSettings,
              `instrument.loadouts.${loadoutId}.directions.${selectedDirection.id}.func`,
              selectedDirection.func,
            )}
            placeholder="Please enter a function..."
            onChange={(e) => {
              onSettingsChange(
                getSettingsDiff(undefined, modifiedSettings, {
                  action: SettingsActions.update as SettingsAction,
                  change: {
                    key: SettingsChanges.direction,
                    value: {
                      loadoutId: loadoutId,
                      id: selectedDirection.id,
                      update: e.target.value,
                      func: true,
                    },
                  },
                }),
              );
            }}
          />
          <div className="flex justify-between items-center">
            <button
              type="button"
              className="inline-flex mt-3 justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() =>
                handleClear(
                  true,
                  directions.map((d) => d.id),
                )
              }
            >
              RESET ALL TO DEFAULTS
            </button>
            <button
              type="button"
              className="inline-flex mt-3 justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() =>
                handleClear(
                  false,
                  directions.map((d) => d.id),
                )
              }
            >
              CLEAR ALL
            </button>
          </div>
        </>
      )}
      {loadoutSelected && (
        <>
          <label
            htmlFor="name"
            className="block text-sm font-medium leading-6 text-white"
          >
            Name
          </label>
          <input
            name="name"
            id="name"
            className="block w-full rounded-md border-0 p-2 mb-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={getSettingValue(
              modifiedSettings,
              `instrument.loadouts.${loadoutId}.name`,
              loadoutName,
            )}
            placeholder="Please enter a name..."
            onChange={(e) => {
              onSettingsChange(
                getSettingsDiff(undefined, modifiedSettings, {
                  action: SettingsActions.update as SettingsAction,
                  change: {
                    key: SettingsChanges.loadout,
                    value: {
                      id: loadoutId,
                      update: e.target.value,
                    },
                  },
                }),
              );
            }}
          />
        </>
      )}
    </div>
  );
};
