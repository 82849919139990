import {
  useCkGenesisBalanceOf,
  useCkGenesisTotalCount,
  readCkGenesis,
} from './generated-abi';
import { setIsCkHolder } from './eth-slice';
import { store } from '../../../app/store';

export async function CreepKidCheckerAction(
  address: `0x${string}`,
): Promise<boolean> {
  try {
    const ckBalance: bigint = await readCkGenesis({
      functionName: 'balanceOf',
      args: [address],
    });
    console.log(`creep kids checker data from action: ${ckBalance}`);
    store.dispatch(setIsCkHolder(ckBalance > BigInt(0)));
    return ckBalance > BigInt(0);
  } catch (e) {
    console.log(`creep kids checker error: ${e}`);
    throw new Error(`creep kids checker error: ${e}`);
  }
}

export function CreepKidCheckerHook({ address }: { address: `0x${string}` }) {
  const { data } = useCkGenesisTotalCount();
  const { data: balanceData } = useCkGenesisBalanceOf({
    args: [address],
  });

  console.log(`creep kids checker data: ${data}`);
  console.log(`balancedata: ${balanceData}`);

  return <div></div>;
}
