/* eslint-disable jsx-a11y/anchor-is-valid */

import { Header } from '../features/header';
import { SignIn, AwaitSiwe } from '../features/sign-in';
import { NoCreepKid } from '../features/web3/eth/no-creep-kid';
import { VoteImageDispay } from '../features/vote/vote-image';

export function Landing() {
  //TODO this is now deprecated -- Generate is the "landing page", so yeah
  return (
    <div>
      <Header />
      <SignIn />
      <AwaitSiwe />
      <NoCreepKid />
      <VoteImageDispay />
    </div>
  );
}
