export function PrivacyPolicy() {
  return (
    <div className="px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-100">
        <div style={{ padding: '20px' }}>
          <h1 className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">
            Privacy Policy for nilor
          </h1>
          <p>Last Updated: January 15th, 2024</p>

          <p>
            Welcome to nilor. This privacy policy outlines how we collect, use,
            store, and share information provided by our users. Our utmost
            priority is to respect your privacy and ensure the safety and
            confidentiality of your data.
          </p>

          <h2 className="text-2xl">1. Information We Collect</h2>
          <p>We collect the following types of information from our users:</p>
          <ul>
            <li>
              <strong>Email Address</strong>: We require your email address for
              account registration, communication, and security purposes.
            </li>
            <li>
              <strong>Personal Information</strong>: Any additional information
              you choose to provide, such as your name, is collected when you
              use our services.
            </li>
            <li>
              <strong>Generated Images</strong>: Images you create using our AI
              technology are stored on our servers.
            </li>
          </ul>

          <h2 className="text-2xl">2. Use of Information</h2>
          <p>The information we collect is used for the following purposes:</p>
          <ul>
            <li>
              <strong>Account Management</strong>: Your email address and
              personal information help us manage and secure your account.
            </li>
            <li>
              <strong>Service Improvement</strong>: We continually strive to
              improve our application based on the information and feedback we
              receive from you.
            </li>
            <li>
              <strong>Image Storage</strong>: Images you generate are stored to
              allow you to access and share them as needed.
            </li>
          </ul>

          <h2 className="text-2xl">3. Sharing of Information</h2>
          <p>
            We do not share your personal information with third parties, except
            in the following circumstances:
          </p>
          <ul>
            <li>
              <strong>Consent</strong>: If we have your explicit consent to
              share the information.
            </li>
            <li>
              <strong>Legal Requirements</strong>: If required by law or in
              response to legal process or lawful requests, including from law
              enforcement and government agencies.
            </li>
          </ul>

          <h2 className="text-2xl">4. Data Storage and Security</h2>
          <p>
            We are committed to ensuring that your information is secure. In
            order to prevent unauthorized access or disclosure, we have put in
            place suitable physical, electronic, and managerial procedures to
            safeguard and secure the information we collect online.
          </p>

          <h2 className="text-2xl">5. User Rights</h2>
          <p>
            You have the right to access, correct, or delete your personal
            information. You can do this using the tools provided in our
            application or by contacting us directly.
          </p>

          <h2 className="text-2xl">6. Changes to This Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            We encourage you to review this Privacy Policy periodically for any
            changes.
          </p>

          <h2 className="text-2xl">7. Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at privacy at nilor .cool
          </p>
        </div>
      </div>
    </div>
  );
}
