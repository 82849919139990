import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { EReducerState } from '../../app/enum';
import {
  fetchLeaderImagesAsync,
  selectLeaderImages,
  selectLeaderStatus,
} from './vote-leaders-slice';
import { DisplayVoteContent } from './types';
import {
  PlusIcon,
  ArrowPathRoundedSquareIcon,
  ArrowLeftIcon,
} from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { selectIsCkHolder } from '../web3/eth/eth-slice';
import { selectSiweIsLoggedIn } from '../auth/siwe-auth/siwe-auth-slice';

export function VoteLeadersDisplay() {
  const { isConnected } = useAccount();
  const isCkHolder = useAppSelector(selectIsCkHolder);
  const isSiweLoggedIn = useAppSelector(selectSiweIsLoggedIn);
  return (
    <div>{isConnected && isCkHolder && isSiweLoggedIn && <VoteLeaders />}</div>
  );
}

export function VoteLeaders() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const images: DisplayVoteContent[] = useAppSelector(selectLeaderImages);
  const voteStatus: EReducerState = useAppSelector(selectLeaderStatus);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (images.length === 0) {
      dispatch(fetchLeaderImagesAsync());
    }
    //Only want this to run once on component load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImageClick = (image: any) => {
    if (selectedImage === image) {
      setSelectedImage(null);
    } else {
      setSelectedImage(image);
    }
  };

  return (
    <div className="h-[100vh] overflow-auto w-[100vw]">
      <div className="pt-16">
        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
          <div className="flex justify-between">
            <h2 className="text-2xl font-bold tracking-tight text-slate-50">
              Leader Board
            </h2>

            <button
              type="button"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => navigate('/')}
            >
              <ArrowLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Back to Voting
            </button>
          </div>

          <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            {images.map((leaderImage, index) => (
              <div
                key={leaderImage.content.id}
                className={`${
                  selectedImage === leaderImage
                    ? 'h-[512px] w-[512px] inset-0 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
                    : 'group relative lg:h-80'
                }`}
                onClick={() => handleImageClick(leaderImage)}
                style={{
                  zIndex: selectedImage === leaderImage ? 50 : 0,
                }}
              >
                <div
                  className={`aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none transition-all duration-500 ${
                    selectedImage ? '' : 'group-hover:opacity-75'
                  }`}
                >
                  <img
                    src={leaderImage.imageData}
                    alt={'scary-stuff'}
                    className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                  />
                </div>
                <div className="mt-4 flex justify-center">
                  <div>
                    <h3 className="text-lg font-mono font-bold text-violet-300">
                      <span aria-hidden="true" className="absolute inset-0" />
                      Score:&nbsp;
                    </h3>
                  </div>
                  <p className="text-lg font-mono font-bold text- text-violet-200">
                    {leaderImage.content.coolRanking}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center pt-5">
            {voteStatus === EReducerState.IDLE && (
              <button
                type="button"
                className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => dispatch(fetchLeaderImagesAsync())}
              >
                <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                MORE
                <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              </button>
            )}
            {voteStatus !== EReducerState.IDLE && (
              <button
                type="button"
                className="inline-flex items-center gap-x-2 rounded-md bg-red-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => dispatch(fetchLeaderImagesAsync())}
              >
                <ArrowPathRoundedSquareIcon
                  className="-ml-0.5 h-5 w-5 animate-spin"
                  aria-hidden="true"
                />
                <span>L o a d i n g</span>
                <span className="animate-ellipses-reveal">...</span>
                <ArrowPathRoundedSquareIcon
                  className="-ml-0.5 h-5 w-5 animate-spin"
                  aria-hidden="true"
                />
              </button>
            )}
          </div>
          {selectedImage && (
            <div
              className="fixed inset-0 bg-purple-100 bg-opacity-50 z-40"
              onClick={() => setSelectedImage(null)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
