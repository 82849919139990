import { Routes, Route } from 'react-router-dom';
import {
  // Dev,
  Landing,
  Leaderboard,
  Generate,
  Privacy,
  About,
  Party,
  Consult,
} from './pages';
// @ts-ignore:next-line
import { withOneTabEnforcer } from 'react-one-tab-enforcer';

import './App.css';

const OneTab = () => (
  <div className="flex justify-center items-center h-screen text-white">
    <span className="p-3">
      the nilor app currently only supports single tab usage.
    </span>
  </div>
);

const GenerateOneTab = withOneTabEnforcer({
  appName: 'nilor',
  OnlyOneTabComponent: OneTab,
})(Generate);

function App() {
  return (
    <Routes>
      <Route path="/" element={<GenerateOneTab />} />
      {/* <Route path="/dev" element={<Dev />} /> */}
      <Route path="/leaderboard" element={<Leaderboard />} />
      <Route path="/vote" element={<Landing />} />
      <Route path="/generate" element={<GenerateOneTab />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/about" element={<About />} />
      <Route path="/party/:collectionId" element={<Party />} />
      <Route path="/consult/:collectionId" element={<Consult />} />
      <Route path="*" element={<Generate />} />
    </Routes>
  );
}

export default App;
