import { useState } from 'react';
import { InstrumentTopDrawer } from './InstrumentTopDrawer';
import { InstrumentLeftDrawer } from './InstrumentLeftDrawer';
import { InstrumentDial } from './InstrumentDial';
import { InstrumentBottomDrawer } from './InstrumentBottomDrawer';
import { ImagePosition } from '../features/generate/generate-images';
import { ImageSelectionData, getInfluenceColor } from '../utils';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Loadout } from '../app/types/instrument';
import { InstrumentBody } from './InstrumentBody';
import {
  selectParentInfluence,
  setParentInfluence,
} from '../features/userPrefs/userPrefs-slice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { GenerateImageModalProps } from './ViewImageModal';

interface InstrumentProps {
  selectedImages: ImagePosition[];
  onSelectImages: (images: ImagePosition[]) => void;
  selectedImagesData: ImageSelectionData[];
  loadout: Loadout;
  moveModalProps: GenerateImageModalProps;
  showLeftDrawer?: boolean;
  showTopDrawer?: boolean;
  showBottomDrawer?: boolean;
  showDial?: boolean;
}

export const Instrument = ({
  selectedImages,
  onSelectImages,
  selectedImagesData,
  loadout,
  moveModalProps,
  showLeftDrawer = true,
  showTopDrawer = true,
  showBottomDrawer = true,
  showDial = true,
}: InstrumentProps) => {
  const [minimized, setMinimized] = useState(false);
  const [showInfluenceValue, setShowInfluenceValue] = useState(false);
  const [timeouts, setTimeouts] = useState([]);
  const parentInfluence = useAppSelector(selectParentInfluence);
  const dispatch = useAppDispatch();

  const handleInfluenceChange = (val: number) => {
    if (val !== parentInfluence) {
      dispatch(setParentInfluence(val));
      setShowInfluenceValue(true);
      timeouts.forEach((id) => clearTimeout(id));

      const timeoutId = setTimeout(() => {
        setTimeouts(timeouts.filter((id) => id !== timeoutId));
        setShowInfluenceValue(false);
      }, 4000);

      // @ts-ignore
      setTimeouts([...timeouts, timeoutId]);
    }
  };

  if (minimized) {
    return (
      <div
        className="flex justify-center items-center w-[4rem] h-8 bg-instrument-body cursor-pointer rounded-t-md"
        onClick={() => setMinimized(false)}
        title="Show Instrument"
      >
        <ChevronDownIcon className="w-full h-full text-white" />
      </div>
    );
  }

  return (
    <>
      {showInfluenceValue && (
        <div className="relative top-[-2rem] text-white text-bold text-8xl text-center select-none">
          <span
            className={`rounded-2xl p-3 opacity-70 ${getInfluenceColor(
              parentInfluence,
            )}`}
          >
            {parentInfluence}
          </span>
        </div>
      )}
      <div className="grid grid-cols-[3.25rem_37rem_4.5rem]">
        {showLeftDrawer ? (
          <div className="relative top-[18px]">
            <InstrumentLeftDrawer
              selectedImages={selectedImagesData}
              clearSelectionCallback={() => onSelectImages([])}
            />
          </div>
        ) : (
          <div></div>
        )}
        <div className="flex flex-col justify-center items-center">
          {showTopDrawer ? (
            <div className="z-10">
              <InstrumentTopDrawer
                canClose={false}
                selectedImages={selectedImages}
              />
            </div>
          ) : (
            <div></div>
          )}
          <div className="w-[37rem] z-20">
            <InstrumentBody
              selectedImages={selectedImages}
              onSelectImages={onSelectImages}
              selectedImagesData={selectedImagesData}
              loadout={loadout}
              onMinimize={() => setMinimized(!minimized)}
            />
          </div>
          {showBottomDrawer ? (
            <div className="w-[34.5rem] z-10">
              <InstrumentBottomDrawer
                canClose={false}
                selectedImages={selectedImagesData}
                moveModalProps={moveModalProps}
                clearSelectionCallback={() => onSelectImages([])}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>
        {showDial ? (
          <div className="relative top-[8px] right-[134px]">
            <InstrumentDial
              parentInfluence={parentInfluence}
              onValueChange={handleInfluenceChange}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};
