import { BeakerIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { batchExploreFunction } from '../features/generate/ExploreButtons';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  generateSessionImagesAsync,
  selectGenerateImages,
  selectLoadingInstrument,
} from '../features/generate/generate-images-slice';
import { ImagePosition } from '../features/generate/generate-images';
import { get, isEmpty } from 'lodash';
import {
  ImageSelectionData,
  generateDefaultDirections,
  getImageIds,
  isPhoneRes,
} from '../utils';
import { Loadout } from '../app/types/instrument';
import { Spinner } from 'flowbite-react';
import {
  selectImageShape,
  selectParentInfluence,
} from '../features/userPrefs/userPrefs-slice';

interface InstrumentBodyProps {
  selectedImages: ImagePosition[];
  onSelectImages: (images: ImagePosition[]) => void;
  selectedImagesData: ImageSelectionData[];
  loadout: Loadout;
  onMinimize?: () => void;
}

export const InstrumentBody = ({
  selectedImages,
  onSelectImages,
  selectedImagesData,
  loadout,
  onMinimize = () => {},
}: InstrumentBodyProps) => {
  const dispatch = useAppDispatch();
  const images = useAppSelector(selectGenerateImages);
  const loadingInstrument = useAppSelector(selectLoadingInstrument);
  const imageShape = useAppSelector(selectImageShape);
  const parentInfluence = useAppSelector(selectParentInfluence);

  const directions = get(loadout, 'directions', generateDefaultDirections());
  const combineFunc = directions.map((direction) => direction.func).join(' ');
  const combineContext = directions
    .map((direction) => direction.context)
    .join(' ');

  return (
    <div
      className={`relative w-full bg-instrument-body p-2 md:p-4 md:rounded-tl-2xl md:rounded-b-2xl`}
    >
      {!isPhoneRes() && (
        <div
          className="absolute flex justify-center items-center right-0 top-[-1rem] w-12 h-6 rounded-t-md bg-instrument-body"
          onClick={onMinimize}
          title="Minimize"
        >
          <ChevronUpIcon className="w-full h-full text-white cursor-pointer" />
        </div>
      )}
      <div className="grid grid-cols-[7fr_1fr] gap-2 md:gap-3">
        {loadingInstrument && (
          <div className="flex justify-center items-center w-full h-full">
            <Spinner size="xl" />
          </div>
        )}
        {!loadingInstrument && (
          <div className="grid grid-cols-3 gap-2 md:gap-3">
            {directions.map((direction) => (
              <div
                className={`flex active:scale-75 transition-transform bg-instrument-body-direction justify-center items-center h-[2.5rem] md:h-[3.5rem] rounded-2xl cursor-pointer px-2 hover:bg-stone-200`}
                onClick={batchExploreFunction(
                  {
                    context: direction.context,
                    prephixPhrase: direction.func,
                    display: direction.label,
                  },
                  selectedImagesData,
                  imageShape,
                  parentInfluence,
                  dispatch,
                  generateSessionImagesAsync,
                )}
                key={`instrument-direction-${direction.id}`}
                title={'Direction: ' + direction.label}
              >
                <span className="truncate">{direction.label}</span>
              </div>
            ))}
          </div>
        )}
        <div className="grid grid-rows-2 gap-2 md:gap-3">
          <div
            className="flex justify-center items-center select-none active:scale-75 transition-transform h-[2.5rem] md:h-[3.5rem] rounded-2xl p-2 bg-transparent text-xl text-white hover:bg-white hover:text-black cursor-pointer"
            title={isEmpty(selectedImages) ? 'Select All' : 'Deselect All'}
            onClick={() => {
              isEmpty(selectedImages)
                ? onSelectImages(getImageIds(images))
                : onSelectImages([]);
            }}
          >
            {selectedImages.length}
          </div>
          <div
            className="flex justify-center items-center active:scale-75 transition-transform h-[2.5rem] md:h-[3.5rem] rounded-2xl p-2 bg-slate-900 text-white hover:bg-white hover:text-slate-900 cursor-pointer"
            title="Combine"
            onClick={batchExploreFunction(
              {
                context: combineContext,
                prephixPhrase: combineFunc,
                display: '',
              },
              selectedImagesData,
              imageShape,
              parentInfluence,
              dispatch,
              generateSessionImagesAsync,
            )}
          >
            <BeakerIcon className="h-full w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};
