import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import { config } from '../config/config';
import {
  selectSiweToken,
  setIsLoggedIn,
} from '../features/auth/siwe-auth/siwe-auth-slice';
import { store } from '../app/store';
import { Root } from 'react-dom/client';
import { siweConnectLogin } from '../features/auth/siwe-auth/siwe-connect-login';
import { getAccount } from '@wagmi/core';
import { logout, selectGauthToken } from '../features/auth/gauth/gauth-slice';

if (!config.APP_API_URI) {
  throw new Error('APP_API_URI is not defined, cannot initialize axios');
}

export const apiAxios = axios.create();

apiAxios.interceptors.request.use(
  async (axiosConfig: InternalAxiosRequestConfig) => {
    const state: Root = store.getState();
    const token = selectSiweToken(state) || selectGauthToken(state);

    if (!token) {
      throw new Error('No token found axios interceptor');
    }
    axiosConfig.baseURL = config.APP_API_URI;
    axiosConfig.headers['Authorization'] = `Bearer ${token}`;
    axiosConfig.headers['Content-Type'] = 'application/json';
    return axiosConfig;
  },
  (error) => {
    Promise.reject(error);
  },
);

apiAxios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async function (error) {
    console.log(error.response);
    const originalRequest = error.config;
    const state: Root = store.getState();
    const gauthToken = selectGauthToken(state);

    if (error.response.status === 401 && !originalRequest._retry) {
      // TODO: force sign out for gauth for now
      if (gauthToken) {
        console.log('401 error intercepted, forcing gauth logout');
        store.dispatch(logout());
        return Promise.reject(error);
      }

      console.log('401 error intercepted, retrying with new token');
      const { address, isConnected } = getAccount();
      if (!isConnected || !address) {
        console.log('401 error, not connected, or address');
        return Promise.reject(error);
      }
      originalRequest._retry = true;

      await siweConnectLogin(address, true);
      const state: Root = store.getState();
      const token = selectSiweToken(state);
      if (!token) {
        console.log('401 error, no token');
        return Promise.reject(error);
      }
      originalRequest.headers['Authorization'] = `Bearer ${token}`;
      return apiAxios(originalRequest);
    } else if (originalRequest._retry) {
      console.log('apiAxios 401 error, retry failed, logging out');
      store.dispatch(setIsLoggedIn(false));
    }

    return Promise.reject(error);
  },
);
