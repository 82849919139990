import { useRef } from 'react';
import { DisplayImageContent, GenerateInstrumentDto } from './types';
import { ImageFrame } from './ImageFrame';
import { ImagePosition } from './generate-images';
import { DLinkList } from 'd-link-list';
import { ESAIImageShape } from './enum';
import { GenerateImageModalProps } from '../../components/ViewImageModal';

interface ImageCardProps {
  image: DisplayImageContent;
  imageIndex: number;
  listIndex: number;
  imageList: DLinkList<DisplayImageContent>;
  imageModalProps: GenerateImageModalProps;
  instrument: GenerateInstrumentDto;
  moveModalProps: GenerateImageModalProps;
  isSelected?: boolean;
  selectCallback: (checked: boolean, imageContent: ImagePosition) => void;
  imageSize: number;
  imageRatio: string | undefined;
}

export const ImageCard = ({
  image,
  imageIndex,
  listIndex,
  imageList,
  imageModalProps,
  isSelected,
  selectCallback,
  imageSize,
  imageRatio,
}: ImageCardProps) => {
  const focusDivRef = useRef<HTMLDivElement>(null);
  const imageHeight =
    imageRatio === ESAIImageShape.TALL ? imageSize * 1.75 : imageSize;
  const imageWidth =
    imageRatio === ESAIImageShape.WIDE ? imageSize * 1.75 : imageSize;

  return (
    <div
      className={`transform active:scale-95 transition-transform bg-transparent rounded-2xl cursor-grab hover:opacity-80 ${
        isSelected ? 'outline outline-4 outline-white' : ''
      }`}
      ref={focusDivRef}
      onClick={() => {
        selectCallback(!isSelected, {
          id: image.content.id,
          colNum: listIndex,
          rowNum: imageIndex,
        });
      }}
    >
      <ImageFrame
        image={image}
        listIndex={listIndex}
        imageIndex={imageIndex}
        imageList={imageList}
        modalProps={imageModalProps}
        height={imageHeight}
        width={imageWidth}
      />
    </div>
  );
};
