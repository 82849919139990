import { default as demon } from '../../images/demon.svg';

export function PingingDemon() {
  return (
    <div className="relative isolate pt-14">
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-13rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 translate-y-60 rotate-[30deg] bg-gradient-to-tr from-[#ffffff] to-[#ea0000] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="py-24 sm:py-32 lg:pb-40 ">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 pt-14">
          <div className="mx-auto max-w-2xl text-center">
            <h1 className="text-4xl font-bold font-mono tracking-tight text-red-400 sm:text-2xl">
              <span>Loading more evil images</span>
              <span className="animate-ellipses-reveal ">...</span>
            </h1>
            <div className="flex justify-center pt-3">
              <div className="animate-ping-slow">
                <img
                  src={demon}
                  alt="App screenshot"
                  width={320 / 2}
                  height={320 / 2}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 translate-y-5 bg-gradient-to-tr from-[#ff0000] to-[#ffffff] opacity-20 sm:left-[calc(50%+1rem)] sm-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </div>
  );
}
