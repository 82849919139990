import { GenerateInstrumentDto } from './types';
export const generateDefaultInstrument: GenerateInstrumentDto = {
  instrumentInfo: [
    {
      display: '📸',
      context: 'you are a photographer!',
      prephixPhrase:
        'rewrite the following, but adjusted to suggest realism and detail, like it is a large format photograph: ',
    },
    {
      display: '💸',
      context: 'you are an ad exec!',
      prephixPhrase:
        'Rewrite the following, making sure to produce a visual description of something eminently salable about the concept: ',
    },
    {
      display: '🎨',
      context: 'you are a painter!',
      prephixPhrase:
        'rewrite the following, Transform this scene into a classic painting , with a focus on artistic qualities like visible brushstrokes, textured surfaces, and a rich, vibrant color scheme: ',
    },
    {
      display: '🛋️',
      context: 'you are an therapist!',
      prephixPhrase:
        'rewrite the following, Rewrite the following, replacing all abstract nouns with concrete nouns that visually convey the same abstract nouns in a symbolic way: ',
    },
    {
      display: '🔍',
      context: 'you are an ImageMaker!',
      prephixPhrase:
        'zoom into this idea, becoming more precise, and rewrite it: ',
    },
    {
      display: '🤣',
      context: 'you are an explorer!',
      prephixPhrase:
        'rewrite the following, but make it funny and ridiculous: ',
    },
  ],
};
