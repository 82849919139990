import { Header } from '../features/header';
import { SignIn, AwaitSiwe } from '../features/sign-in';
import { NoCreepKid } from '../features/web3/eth/no-creep-kid';
import { VoteLeadersDisplay } from '../features/vote/vote-leaders';

export function Leaderboard() {
  return (
    <div>
      <Header />
      <SignIn />
      <AwaitSiwe />
      <NoCreepKid />
      <VoteLeadersDisplay />
    </div>
  );
}
