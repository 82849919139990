import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

import {
  GauthCredential,
  ApiGauthCredential,
} from './types';
import { ApiJwt, isApiJwt } from '../../../app/types';
import { AuthState } from '../types';

const API_URI: string = process.env.REACT_APP_API_URI as string;

const initialState: AuthState = {
  token: '',
  status: 'idle',
  isLoggedIn: false,
  isUnauthorized: true,
};

//create a slice of state for the google auth
export const gauthSlice = createSlice({
  name: 'gauth',
  initialState,
  reducers: {
    logout: (state) => {
      state.token = '';
      state.status = 'idle';
      state.isLoggedIn = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.status = 'loading';
        console.log('login async pending');
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.isLoggedIn = true;

        const apiCredential = action.payload as ApiGauthCredential;
        const jwt = apiCredential.jwt as ApiJwt;

        if (!isApiJwt(jwt)) {
          throw new Error(`Invalid jwt on async 
                    login fulfilled: ${JSON.stringify(jwt)}`);
        }

        console.log('login async fulfilled, token', jwt.token);
        state.token = jwt.token;
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.status = 'failed';
        console.log('login async rejected');
        console.error(action.error.message);
      });
  },
});

export const selectGauthIsLoggedIn = (state: RootState) =>
  state.gauth.isLoggedIn;
export const selectGauthToken = (state: RootState) => state.gauth.token;
export const selectGauthStatus = (state: RootState) => state.gauth.status;

export const { logout } = gauthSlice.actions;

//create login async thunk
export const loginAsync = createAsyncThunk(
  'gauth/login',
  async (credentials: GauthCredential) => {
    const token = credentials.token;
    console.log(`token: ${token}`);

    const authEndpoint = `${API_URI}/auth/login/gauth`;
    const response = await fetch(authEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({ token: token }),
    });

    const apiJwt = (await response.json()) as ApiJwt;
    // if(!!apiJwt.statusCode) {
    //   console.log('failed to validate user, statusCode: ', apiJwt.statusCode);
    //   // set some kind of state to show invalid user account on page
    // }
    if (!isApiJwt(apiJwt)) {
      throw new Error('Invalid response from server on async login');
    }

    const payload: ApiGauthCredential = {
      token: credentials.token,
      jwt: apiJwt,
    };
    return payload;
  },
);

export const gauthReducer = gauthSlice.reducer;
