import { Modal } from 'flowbite-react';
import React from 'react';

const customTheme = {
  root: {
    base: 'fixed inset-x-0 top-0 z-50 h-full overflow-y-auto overflow-x-hidden md:inset-0',
  },
  content: {
    inner:
      'relative flex h-full md:h-[85vh] flex-col rounded-lg bg-white shadow',
  },
  body: {
    base: 'grow overflow-y-auto p-4 bg-slate-700',
  },
  header: {
    title: 'text-white text-xl font-medium',
    close: {
      base: 'ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-white hover:bg-gray-200 hover:text-gray-900',
    },
  },
};

const backgroundColor = 'bg-slate-700';

interface ModalBaseProps {
  title: string;
  body: React.ReactNode;
  footer: React.ReactNode;
  menu?: React.ReactNode;
  show: boolean;
  size?: string;
  dismissible?: boolean;
  onClose: () => void;
}

export const ModalBase = ({
  title,
  body,
  footer,
  menu,
  show,
  size = '3xl',
  dismissible = true,
  onClose,
}: ModalBaseProps) => {
  return (
    <Modal
      show={show}
      onClose={onClose}
      size={size}
      dismissible={dismissible}
      theme={customTheme}
    >
      <Modal.Header className={`${backgroundColor}`}>{title}</Modal.Header>
      {menu && <>{menu}</>}
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer className={`${backgroundColor}`}>{footer}</Modal.Footer>
    </Modal>
  );
};
