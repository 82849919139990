import { ENetworkType } from './enum';
import { config } from '../../config/config';

export const getNetworkType = (): ENetworkType => {
  const networkType = config.NETWORK;
  if (!networkType) {
    throw new Error('Network type not defined');
  }
  return networkType as ENetworkType;
};
