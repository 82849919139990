import creep_kid_page from '../../../images/creep_kid_page.png';
import opensea from '../../../images/opensea.svg';
import sea_monster from '../../../images/sea_monster.svg';
import discord from '../../../images/discord.svg';
import { IconWrapper } from '../../../utils';

export function NoCreepKid() {
  return (
    <div>
      <NoCreepKidDisplay />
    </div>
  );
}

const features = [
  {
    name: 'Mint a new one',
    description: {
      html: (
        <>
          <div>
            Visit{' '}
            <a
              href="https://creepkids.io"
              className="text-white dark:text-blue-500 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              creepkids.io&nbsp;
            </a>
            to mint a brand new Creep Kid! You can also find details about the
            smart contract and more.
          </div>
        </>
      ),
    },
    icon: sea_monster,
  },
  {
    name: 'Bid on OpenSea',
    description: {
      html: (
        <>
          <div>
            Visit our{' '}
            <a
              href="https://opensea.io/collection/creep-kids-genesis"
              className="text-white dark:text-blue-500 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              collection on OpenSea&nbsp;
            </a>
            and make a bid!
          </div>
        </>
      ),
    },
    icon: opensea,
  },
  {
    name: 'Lurk on Discord 👀',
    description: {
      html: (
        <>
          <div>
            Pop in the{' '}
            <a
              href="https://discord.gg/5xqEPmDU4X"
              className="text-white dark:text-blue-500 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Creep Kids Discord&nbsp;
            </a>
            -- generate some evil AI images, figure out what's going on, and
            yeah...
          </div>
        </>
      ),
    },
    icon: discord,
  },
];

export default function NoCreepKidDisplay() {
  return (
    <div className="pt-10 h-[100vh] overflow-auto w-[100vw]">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="relative isolate overflow-hidden bg-zinc-900 px-6 py-20 sm:rounded-3xl sm:px-10 sm:py-24 lg:py-24 xl:px-24">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center lg:gap-y-0">
            <div className="lg:row-start-2 lg:max-w-md">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Sorry, site only accessible to Creep Kid NFT owners!
                <br />
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                To vote on AI generated content that gets minted in the next{' '}
                <a
                  href="https://magiceden.io/marketplace/tftc"
                  className="text-white dark:text-blue-500 hover:underline"
                >
                  Tales From The Crypt
                </a>{' '}
                collection, you must hold a Creep Kid NFT in your connected
                wallet. Want one? Here's where:
              </p>
            </div>
            <div className="relative lg:row-span-4">
              <a
                href="https://creepkids.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={creep_kid_page}
                  alt="Product screenshot"
                  className="relative -z-20 min-w-full max-w-xl rounded-xl shadow-xl ring-1 ring-white/10 lg:row-span-4 lg:w-[64rem] lg:max-w-none"
                  width={1308}
                  height={887}
                />
              </a>
            </div>
            <div className="max-w-xl lg:row-start-3 lg:mt-10 lg:max-w-md lg:border-t lg:border-white/10 lg:pt-10">
              <dl className="max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative">
                    <dt className="ml-9 inline-block font-semibold text-white">
                      <IconWrapper
                        icon={feature.icon}
                        alt={feature.name}
                        className="absolute left-1 top-1 h-5 w-5 text-indigo-500"
                      />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description.html}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
      <div
        className=" pointer-events-none absolute left-12 top-1/2 z-10 -translate-y-1/2 transform-gpu blur-3xl lg:bottom-[-12rem] lg:top-auto lg:translate-y-0 lg:transform-gpu"
        aria-hidden="true"
      >
        <div
          className="z-50 aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-25"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </div>
  );
}
