import { Direction } from '../app/types/instrument';

export interface DirectionValue {
  label: string;
  context: string;
  func: string;
}

export const defaultDirectionValues: DirectionValue[] = [
  {
    label: '📸',
    context: 'you are a photographer!',
    func: 'rewrite the following, but adjusted to suggest realism and detail, like it is a large format photograph: ',
  },
  {
    label: '💸',
    context: 'you are an ad exec!',
    func: 'Rewrite the following, making sure to produce a visual description of something eminently salable about the concept: ',
  },
  {
    label: '🎨',
    context: 'you are a painter!',
    func: 'rewrite the following, Transform this scene into a classic painting , with a focus on artistic qualities like visible brushstrokes, textured surfaces, and a rich, vibrant color scheme: ',
  },
  {
    label: '🛋️',
    context: 'you are an therapist!',
    func: 'rewrite the following, Rewrite the following, replacing all abstract nouns with concrete nouns that visually convey the same abstract nouns in a symbolic way: ',
  },
  {
    label: '🔍',
    context: 'you are an ImageMaker!',
    func: 'zoom into this idea, becoming more precise, and rewrite it: ',
  },
  {
    label: '🤣',
    context: 'you are an explorer!',
    func: 'rewrite the following, but make it funny and ridiculous: ',
  },
];

export const generateDefaultDirections = (): Direction[] => {
  return defaultDirectionValues.map((directionValue, index) => ({
    id: `direction-${index}`,
    label: directionValue.label,
    context: directionValue.context,
    func: directionValue.func,
    callback: () => {},
  }));
};
