import { useState } from 'react';
import { ImageSelectionData } from '../utils';
import { useQuery } from '@tanstack/react-query';
import {
  CollectionSort,
  fetchCollections,
  getSortedCollections,
} from '../utils/collections';
import { GenerateCollectionIdentifierDto } from '../features/generate/types';
import { useAppSelector } from '../app/hooks';
import { selectCollectionIdentifier } from '../features/generate/generate-images-slice';
import { filter, find, isEmpty, tail } from 'lodash';
import {
  ECollectionModalType,
  ECollectionType,
} from '../features/generate/enum';
import { Switch } from '@headlessui/react';
import { CollectionCard, OpenCollectionButton } from './CollectionCard';
import { Button, Spinner } from 'flowbite-react';
import { ModalBase } from './ModalBase';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

interface CollectionModalProps {
  modalType: ECollectionModalType;
  selectedImagesData: ImageSelectionData[];
  clearSelectionCallback?: () => void;
  onClose: () => void;
}

export const CollectionModal = ({
  modalType,
  selectedImagesData,
  clearSelectionCallback,
  onClose,
}: CollectionModalProps) => {
  const [showExplorerCollections, setShowExplorerCollections] = useState(true);
  const [lastCreatedCollectionId, setLastCreatedCollectionId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortSelection, setSortSelection] = useState<CollectionSort>(
    CollectionSort.NAME_ASC,
  );

  // queries
  const { data, isLoading, isError } = useQuery({
    queryKey: ['collections'],
    queryFn: fetchCollections,
  });

  const handleClose = () => {
    if (clearSelectionCallback) {
      clearSelectionCallback();
    }
    onClose();
  };

  const createdCollections = data || [];
  const controlsDisabled = isEmpty(createdCollections);
  // filter & sort
  const filteredCollections = filter(createdCollections, (collection) =>
    collection.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );
  const filteredAndSortedCollections = getSortedCollections(
    filteredCollections,
    sortSelection,
  );

  const currentCollection: GenerateCollectionIdentifierDto = useAppSelector(
    selectCollectionIdentifier,
  );
  const firstSelectedImage = selectedImagesData[0];
  const additionalImageIds = tail(
    selectedImagesData.map((img) => img.displayImage?.content.id),
  );

  const lastCreatedCollection = find(data, {
    collectionId: lastCreatedCollectionId,
  });
  const showCollectionType = showExplorerCollections
    ? ECollectionType.EXPLORER
    : ECollectionType.PARTY;

  const modalBody = (
    <>
      {lastCreatedCollection && (
        <div className="flex justify-center items-center gap-2 text-white mb-5">
          <span>{`Last created: ${lastCreatedCollection.name}`}</span>
          <OpenCollectionButton
            collectionId={lastCreatedCollection.collectionId}
            collectionType={lastCreatedCollection.type}
            onClearSelection={clearSelectionCallback}
            onClose={handleClose}
          />
        </div>
      )}
      <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
        {/* new collection tile */}
        {modalType === ECollectionModalType.ADD_TO_COLLECTION && (
          <CollectionCard
            modalType={modalType}
            collection={{
              collectionId: '',
              name: '',
              crestContentId: firstSelectedImage.displayImage?.content.id,
              crestContent: firstSelectedImage.displayImage.content,
              type: showExplorerCollections
                ? ECollectionType.EXPLORER
                : ECollectionType.PARTY,
            }}
            currentCollection={currentCollection}
            clearSelectionCallback={clearSelectionCallback}
            closeModal={handleClose}
            imageForCreate={firstSelectedImage.displayImage}
            additionalImageIds={additionalImageIds}
            onCreateCollection={setLastCreatedCollectionId}
            showCollectionType={showCollectionType}
          />
        )}
        {isLoading && <Spinner />}
        {isError && <div>Error fetching collections</div>}
        {!isLoading &&
          filteredAndSortedCollections.map((collection) => (
            <CollectionCard
              modalType={modalType}
              collection={collection}
              currentCollection={currentCollection}
              clearSelectionCallback={clearSelectionCallback}
              closeModal={handleClose}
              key={collection.collectionId}
              onCreateCollection={setLastCreatedCollectionId}
              allSelectedImageIds={selectedImagesData.map(
                (image) => image.displayImage.content.id,
              )}
              showCollectionType={showCollectionType}
            />
          ))}
      </div>
    </>
  );

  const modalMenu = (
    <div className="flex flex-col md:flex-row items-center gap-3 w-full p-3 bg-slate-700 text-white">
      {/* search */}
      <div className="basis-1/2 w-full">
        <input
          type="text"
          placeholder="Search by name"
          className={`w-full p-2 text-black bg-white rounded-md ${
            controlsDisabled ? 'cursor-not-allowed' : ''
          }`}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          disabled={controlsDisabled}
        />
      </div>
      {/* sort */}
      <div className="basis-1/4 w-full">
        <select
          className={`w-full p-2 text-black bg-white rounded-md ${
            controlsDisabled ? 'cursor-not-allowed' : ''
          }`}
          name="sort"
          id="sort"
          defaultValue={sortSelection}
          onChange={(e) => setSortSelection(parseInt(e.target.value))}
          disabled={controlsDisabled}
        >
          <option value={CollectionSort.NAME_ASC}>{'Name Aa-Zz'}</option>
          <option value={CollectionSort.NAME_DESC}>{'Name Zz-Aa'}</option>
          <option value={CollectionSort.LAST_MODIFIED}>
            {'Last Modified'}
          </option>
          <option value={CollectionSort.LAST_CREATED}>{'Last Created'}</option>
        </select>
      </div>
      {/* filter */}
      <div className="basis-1/4 w-full">
        <Switch.Group as="div" className="flex justify-center items-center">
          <Switch
            checked={!showExplorerCollections}
            onChange={() =>
              setShowExplorerCollections(!showExplorerCollections)
            }
            className={classNames(
              controlsDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
              !showExplorerCollections ? 'bg-fuchsia-400' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-200 focus:ring-offset-2',
            )}
            disabled={controlsDisabled}
          >
            <span
              aria-hidden="true"
              className={classNames(
                !showExplorerCollections ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              )}
            />
          </Switch>
          <Switch.Label as="span" className="ml-3 text-sm">
            <div
              className={`text-sm font-bold font-mono tracking-tight ${
                !showExplorerCollections ? 'text-fuchsia-400' : 'text-gray-200'
              }`}
            >
              {!showExplorerCollections ? 'PARTY' : 'EXPLORER'}
            </div>
          </Switch.Label>
        </Switch.Group>
      </div>
    </div>
  );

  const modalFooter = (
    <>
      <Button className="w-1/2" color="dark" onClick={onClose}>
        Close
      </Button>
    </>
  );

  return (
    <ModalBase
      title="Collections"
      body={modalBody}
      menu={modalMenu}
      footer={modalFooter}
      onClose={onClose}
      show={true}
    />
  );
};
