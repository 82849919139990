import { InstrumentBottomDrawer } from './InstrumentBottomDrawer';
import { ImagePosition } from '../features/generate/generate-images';
import {
  ArchiveBoxIcon,
  BoltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PaperAirplaneIcon,
  RocketLaunchIcon,
} from '@heroicons/react/20/solid';
import { useState } from 'react';
import { InstrumentLeftDrawer } from './InstrumentLeftDrawer';
import { ImageSelectionData, getInfluenceColor } from '../utils';
import { Loadout } from '../app/types/instrument';
import { InstrumentBody } from './InstrumentBody';
import { GenerateImageModalProps } from './ViewImageModal';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  selectParentInfluence,
  setParentInfluence,
} from '../features/userPrefs/userPrefs-slice';

enum MenuItem {
  directions = 'Directions',
  standard = 'Standard',
  influence = 'Influence',
  files = 'Files',
}

interface MobileInstrumentProps {
  selectedImages: ImagePosition[];
  onSelectImages: (images: ImagePosition[]) => void;
  selectedImagesData: ImageSelectionData[];
  loadout: Loadout;
  moveModalProps: GenerateImageModalProps;
}

export const MobileInstrument = ({
  selectedImages,
  onSelectImages,
  selectedImagesData,
  loadout,
  moveModalProps,
}: MobileInstrumentProps) => {
  const [menuItem, setMenuItem] = useState<MenuItem>(MenuItem.directions);
  const [showControls, setShowControls] = useState(true);
  const [showInfluenceValue, setShowInfluenceValue] = useState(false);
  const [timeouts, setTimeouts] = useState([]);
  const parentInfluence = useAppSelector(selectParentInfluence);
  const dispatch = useAppDispatch();

  const handleInfluenceChange = (val: number) => {
    if (val !== parentInfluence) {
      dispatch(setParentInfluence(val));
      setShowInfluenceValue(true);
      timeouts.forEach((id) => clearTimeout(id));

      const timeoutId = setTimeout(() => {
        setTimeouts(timeouts.filter((id) => id !== timeoutId));
        setShowInfluenceValue(false);
      }, 4000);

      // @ts-ignore
      setTimeouts([...timeouts, timeoutId]);
    }
  };

  const buttonClass = 'h-6 w-6';

  return (
    <div className="flex flex-col z-20">
      {showInfluenceValue && (
        <div className="text-white text-bold text-4xl text-center select-none mb-8">
          <span
            className={`rounded-2xl p-3 opacity-70 ${getInfluenceColor(
              parentInfluence,
            )}`}
          >
            {parentInfluence}
          </span>
        </div>
      )}
      <div
        className="flex justify-center relative ml-auto mr-auto h-6 bg-instrument-bottomdrawer text-white rounded-t-2xl cursor-pointer w-full"
        onClick={() => setShowControls(!showControls)}
      >
        {showControls ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </div>
      {showControls && (
        <>
          {menuItem === MenuItem.directions && (
            <InstrumentBody
              selectedImages={selectedImages}
              onSelectImages={onSelectImages}
              selectedImagesData={selectedImagesData}
              loadout={loadout}
            />
          )}
          {menuItem === MenuItem.standard && (
            <InstrumentBottomDrawer
              canClose={false}
              selectedImages={selectedImagesData}
              moveModalProps={moveModalProps}
              clearSelectionCallback={() => onSelectImages([])}
            />
          )}
          {menuItem === MenuItem.influence && (
            <div className="flex justify-center items-center w-full bg-instrument-body px-4 py-[22px]">
              <input
                id="minmax-range"
                type="range"
                min={0}
                max={100}
                value={parentInfluence}
                onChange={(e) =>
                  handleInfluenceChange(parseInt(e.target.value))
                }
                className="h-4 w-full bg-gray-200 rounded-lg appearance-none cursor-pointer custom-range-influence"
              />
            </div>
          )}
          {menuItem === MenuItem.files && (
            <InstrumentLeftDrawer
              selectedImages={selectedImagesData}
              clearSelectionCallback={() => onSelectImages([])}
            />
          )}
          <div className="flex justify-between items-center gap-3 p-3 bg-black text-white">
            <div
              className={`flex basis-1/3 justify-center items-center ${
                menuItem === MenuItem.directions
                  ? 'bg-instrument-leftdrawer-add'
                  : 'bg-transparent'
              } py-1 rounded-lg`}
              onClick={() => setMenuItem(MenuItem.directions)}
            >
              {<RocketLaunchIcon className={buttonClass} />}
            </div>
            <div
              className={`flex basis-1/3 justify-center items-center ${
                menuItem === MenuItem.standard
                  ? 'bg-instrument-leftdrawer-add'
                  : 'bg-transparent'
              } py-1 rounded-lg`}
              onClick={() => setMenuItem(MenuItem.standard)}
            >
              {<PaperAirplaneIcon className={buttonClass} />}
            </div>
            <div
              className={`flex basis-1/3 justify-center items-center ${
                menuItem === MenuItem.influence
                  ? 'bg-instrument-leftdrawer-add'
                  : 'bg-transparent'
              } py-1 rounded-lg`}
              onClick={() => setMenuItem(MenuItem.influence)}
            >
              {<BoltIcon className={buttonClass} />}
            </div>
            <div
              className={`flex basis-1/3 justify-center items-center ${
                menuItem === MenuItem.files
                  ? 'bg-instrument-leftdrawer-add'
                  : 'bg-transparent'
              } py-1 rounded-lg`}
              onClick={() => setMenuItem(MenuItem.files)}
            >
              {<ArchiveBoxIcon className={buttonClass} />}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
