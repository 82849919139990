import { find, get, matchesProperty, omit, set, split } from 'lodash';
import { defaultDirectionValues } from './directions';

export interface ExplorerSettings {
  theme: string;
  activeCollection: string;
}

export interface DirectionSettings {
  label: string;
  context: string;
  func: string;
}

export interface LoadoutSettings {
  name: string;
  directions: DirectionSettings[];
}

export interface InstrumentSettings {
  imageShape: string;
  activeLoadoutId: string;
  loadouts: LoadoutSettings[];
}

export interface Settings {
  explorer: ExplorerSettings;
  instrument: InstrumentSettings;
}

export interface ModifiedSettings {
  activeLoadoutId?: string;
  loadouts?: {
    id?: string;
    name?: string;
    action?: SettingsAction;
    directions?: {
      label?: string;
      contex?: string;
      func?: string;
    }[];
  };
}

export type SettingsAction = 'create' | 'delete' | 'update';
export const SettingsActions = {
  create: 'create',
  delete: 'delete',
  update: 'update',
};
export type SettingsChange = 'loadout' | 'direction';
export const SettingsChanges = {
  activeLoadoutId: 'activeLoadoutId',
  loadout: 'loadout',
  direction: 'direction',
};

export interface SettingsDiff {
  action: SettingsAction;
  change: { key: string; value?: any };
}

export const getSettingsDiff = (
  savedSettings: Settings,
  modifiedSettings: ModifiedSettings,
  diff: SettingsDiff,
): ModifiedSettings => {
  const { key, value } = diff.change;

  switch (diff.action) {
    case SettingsActions.create:
      if (key === SettingsChanges.loadout) {
        // simply create a new loadout item with a timestamped default name
        const loadouts = modifiedSettings.loadouts || {};
        const name = value.name;
        const updatedLoadouts = {
          ...loadouts,
          [name]: {
            name: name,
            id: name,
            action: 'create',
            directions: new Array(6).fill({}).map((_, index) => ({
              id: index,
              label: value.fillDefault
                ? defaultDirectionValues[index].label
                : '',
              context: value.fillDefault
                ? defaultDirectionValues[index].context
                : '',
              func: value.fillDefault ? defaultDirectionValues[index].func : '',
            })),
          },
        };
        return { ...modifiedSettings, ...{ loadouts: updatedLoadouts } };
      }

      return modifiedSettings;
    case SettingsActions.delete:
      if (key === SettingsChanges.loadout) {
        const loadouts = modifiedSettings.loadouts || {};
        const modifiedLoadout = loadouts[value.id];

        // if we find it in our modified settings, remove it
        if (modifiedLoadout) {
          const updatedLoadouts = omit(loadouts, value.id);
          return { ...modifiedSettings, ...{ loadouts: updatedLoadouts } };
        } else {
          // otherwise create a new entry with delete action
          return set(modifiedSettings, `loadouts.${value.id}`, {
            id: value.id,
            action: 'delete',
          });
        }
      }

      return modifiedSettings;
    case SettingsActions.update:
      if (key === SettingsChanges.activeLoadoutId) {
        return { ...modifiedSettings, activeLoadoutId: value };
      }
      if (key === SettingsChanges.loadout) {
        return {
          ...modifiedSettings,
          loadouts: {
            ...modifiedSettings.loadouts,
            [value.id]: {
              ...get(modifiedSettings, `loadouts[${value.id}]`, {}),
              id: value.id,
              name: value.update,
              ...(get(modifiedSettings, `loadouts[${value.id}].action`) !==
                SettingsActions.create && { action: SettingsActions.update }),
            },
          },
        };
      }
      if (key === SettingsChanges.direction) {
        const direction = get(
          modifiedSettings,
          `loadouts[${value.loadoutId}].directions[${value.id}]`,
          {},
        );
        const newDirection = {
          ...direction,
          ...(value.context
            ? { context: value.update }
            : value.func
            ? { func: value.update }
            : { label: value.update }),
        };
        if (
          get(modifiedSettings, `loadouts[${value.loadoutId}].action`) !==
          SettingsActions.create
        ) {
          set(
            modifiedSettings,
            `loadouts[${value.loadoutId}].id`,
            value.loadoutId,
          );
          set(
            modifiedSettings,
            `loadouts[${value.loadoutId}].action`,
            SettingsActions.update,
          );
        }

        return set(
          modifiedSettings,
          `loadouts[${value.loadoutId}].directions[${value.id}]`,
          newDirection,
        );
      }
    default:
      return modifiedSettings;
  }
};

/*
  KEY STRUCTURE:
  settings
    .explorer
      .theme
      .activeCollection
    .instrument
      .imageShape
      .activeLoadoutId
      .loadouts
        .name
        .directions
          .label
          .context
          .func
*/

export const getSettingValue = (
  modifiedSettings: ModifiedSettings,
  key: string,
  savedValue: any,
) => {
  const keySplit = split(key, '.');
  switch (keySplit[0]) {
    case 'instrument':
      switch (keySplit[1]) {
        case 'activeLoadoutId':
          return get(modifiedSettings, 'activeLoadoutId', savedValue);
        case 'loadouts':
          if (keySplit[3] === 'name') {
            // instrument.loadouts.$.name
            return get(
              modifiedSettings,
              `loadouts.[${keySplit[2]}.name]`,
              savedValue,
            );
          }
          if (keySplit[3] === 'directions') {
            // instrument.loadouts.$.directions.$.*
            return get(
              modifiedSettings,
              `loadouts.[${keySplit[2]}.directions.${keySplit[4]}].${keySplit[5]}`,
              savedValue,
            );
          }
      }
    default:
      return savedValue;
  }
};

export const existingLoadoutRemoved = (
  id: string,
  settings: ModifiedSettings,
) => {
  const loadout = find(settings.loadouts, matchesProperty('id', id));
  return loadout?.action === SettingsActions.delete;
};
