import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Consultation,
  ConsultationAnswer,
  getCollection,
  getConsultation,
  submitConsultation,
  updateConsultation,
  visualDescriptionPlaceholder,
} from '../utils/collections';
import { Button, Spinner, Textarea } from 'flowbite-react';
import { every, filter, find, get, isEmpty, some } from 'lodash';
import { useLocation } from 'react-router-dom';
import { getDefaultImageSize } from '../utils';

enum SUBMISSION_STATUS {
  NOT_READY,
  READY,
  SUBMITTING,
  ERROR,
}

interface ConsultationFormProps {
  collectionId: string;
}

export const ConsultationForm = ({ collectionId }: ConsultationFormProps) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [loadingImages, setLoadingImages] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [answers, setAnswers] = useState([] as ConsultationAnswer[]);
  const [submissionStatus, setSubmissionStatus] = useState<SUBMISSION_STATUS>(
    SUBMISSION_STATUS.NOT_READY,
  );
  const [userEdited, setUserEdited] = useState(false);

  // look for username query param
  const useRouterQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const routerQuery = useRouterQuery();
  const usernameParam = routerQuery.get('username') || undefined;

  // queries
  const {
    data: collectionData,
    isLoading: isLoadingCollection,
    isError: isErrorCollection,
  } = useQuery({
    queryKey: ['collection', collectionId],
    queryFn: () => getCollection(collectionId),
  });

  const {
    data: consultationData,
    isLoading: isLoadingConsultation,
    // isError: isErrorConsultation,
  } = useQuery({
    queryKey: ['consultation', collectionId, usernameParam],
    queryFn: () => getConsultation({ collectionId, username: usernameParam }),
  });

  // mutations
  const submitConsultationMutation = useMutation({
    mutationFn: consultationData ? updateConsultation : submitConsultation,
    onMutate: () => {
      setSubmissionStatus(SUBMISSION_STATUS.SUBMITTING);
    },
    onSuccess: () => {
      setSubmissionStatus(SUBMISSION_STATUS.NOT_READY);
    },
    onError: () => {
      setSubmissionStatus(SUBMISSION_STATUS.ERROR);
    },
  });

  const handleVisualDescriptionChange = (
    contentId: string,
    visualDescription: string,
  ) => {
    const updatedAnswers = [...answers];
    updatedAnswers[selectedIndex].contentId = contentId;
    updatedAnswers[selectedIndex].visualDescription = visualDescription;
    setAnswers(updatedAnswers);

    if (!userEdited) setUserEdited(true);
  };

  const handleSave = () => {
    const answersWithIds = answers.map((a) => ({
      ...a,
      ...(consultationData && {
        id: find(consultationData.answers, { contentId: a.contentId })?.id,
      }),
    }));
    const consultation = {
      collectionId,
      answers: filter(answersWithIds, (a) => !!a.visualDescription),
      ...{ id: consultationData?.id },
    };

    submitConsultationMutation.mutate(consultation);
    setSubmissionStatus(SUBMISSION_STATUS.SUBMITTING);
  };

  const collectionContent = get(collectionData, 'content', []);
  const getVisualDescription = useCallback(
    (contentId: string) => {
      return find(answers, { contentId })?.visualDescription;
    },
    [answers],
  );

  useEffect(() => {
    if (isEmpty(answers) && !isLoadingCollection && !isLoadingConsultation) {
      setAnswers(
        collectionData.content.map((content: any) => {
          const visualDescription =
            consultationData?.answers?.find((a) => a.contentId === content.id)
              ?.visualDescription || visualDescriptionPlaceholder;
          return {
            contentId: content.id,
            visualDescription: visualDescription,
          };
        }),
      );
    }

    if (
      submissionStatus !== SUBMISSION_STATUS.READY &&
      submissionStatus !== SUBMISSION_STATUS.SUBMITTING &&
      every(collectionContent, (c) => getVisualDescription(c.id))
    ) {
      setSubmissionStatus(SUBMISSION_STATUS.READY);
    }

    if (
      submissionStatus === SUBMISSION_STATUS.READY &&
      some(collectionContent, (c) => !getVisualDescription(c.id))
    ) {
      setSubmissionStatus(SUBMISSION_STATUS.NOT_READY);
    }

    // preload images
    if (collectionData?.content && !imagesLoaded && !loadingImages) {
      setLoadingImages(true);
      const loadImage = (imageUrl: string) => {
        return new Promise((resolve, reject) => {
          const loadImg = new Image();
          loadImg.src = imageUrl;
          loadImg.onerror = (err) => reject(err);
        });
      };

      Promise.all(
        collectionData.content.map((c: any) => {
          loadImage(c.contentUrl);
        }),
      )
        .then(() => {
          setImagesLoaded(true);
          setLoadingImages(false);
        })
        .catch((err) => console.log('Failed to load images', err));
    }
  }, [
    selectedIndex,
    collectionData,
    consultationData,
    answers,
    submissionStatus,
    imagesLoaded,
    loadingImages,
    collectionContent,
    getVisualDescription,
  ]);

  const currentAnswer = get(
    answers,
    `[${selectedIndex}]`,
    {},
  ) as ConsultationAnswer;

  const emptyCollection = collectionData && isEmpty(collectionData.content);
  const showConsultationPrompts =
    !isLoadingCollection &&
    !isErrorCollection &&
    !usernameParam &&
    !emptyCollection;
  const imageSize = getDefaultImageSize();

  return (
    <div className="flex justify-center items-center">
      {isLoadingCollection && !imagesLoaded && (
        <Spinner className="w-12 h-12" />
      )}
      {isErrorCollection && (
        <span className="text-white">Couldn't load this consultation.</span>
      )}
      {emptyCollection && (
        <span className="text-white">Collection is empty.</span>
      )}
      {showConsultationPrompts && (
        <div className="flex flex-col gap-4 w-[358px] md:w-[512px]">
          <div className="flex-grow">
            <img
              src={collectionData.content[selectedIndex].contentUrl}
              alt={`consultation-${selectedIndex}`}
              style={{
                height: `${imageSize}px`,
                width: `${imageSize}px`,
                objectFit: 'contain',
              }}
            />
          </div>
          <div className="flex justify-between text-white">
            <span>Your description:</span>
            <span>{`${selectedIndex + 1} / ${
              collectionData.content.length
            }`}</span>
          </div>
          <Textarea
            className="w-full h-56 md:h-[270px]"
            value={getVisualDescription(currentAnswer.contentId)}
            onChange={(e) =>
              handleVisualDescriptionChange(
                currentAnswer.contentId,
                e.target.value,
              )
            }
          />
          <div className="flex justify-between">
            <div className="w-[8rem]">
              {selectedIndex ? (
                <Button
                  className="w-full active:scale-75 transition-transform"
                  color="blue"
                  onClick={() => setSelectedIndex(selectedIndex - 1)}
                >
                  Previous
                </Button>
              ) : null}
            </div>
            <div className="w-[8rem]">
              {selectedIndex < answers.length - 1 && (
                <Button
                  className="w-full active:scale-75 transition-transform"
                  color="blue"
                  onClick={() => setSelectedIndex(selectedIndex + 1)}
                >
                  Next
                </Button>
              )}
            </div>
          </div>
          <div className="flex justify-center">
            {userEdited && (
              <Button
                className="w-full active:scale-75 transition-transform"
                color="blue"
                onClick={handleSave}
                disabled={submissionStatus === SUBMISSION_STATUS.SUBMITTING}
              >
                {submissionStatus === SUBMISSION_STATUS.READY ? (
                  'Submit'
                ) : submissionStatus === SUBMISSION_STATUS.ERROR ? (
                  'Something went wrong. Try again.'
                ) : (
                  <Spinner />
                )}
              </Button>
            )}
          </div>
        </div>
      )}
      {!isLoadingCollection && !isErrorCollection && usernameParam && (
        <ConsultationReadOnly
          consultation={consultationData}
          content={get(collectionData, 'content', [])}
        />
      )}
    </div>
  );
};

interface ConsultationReadOnlyProps {
  consultation: Consultation | undefined;
  content: any;
}
const ConsultationReadOnly = ({
  consultation,
  content,
}: ConsultationReadOnlyProps) => {
  if (!consultation)
    return <span className="text-white">Couldn't load this consultation.</span>;

  const filteredAnswers = consultation?.answers.filter((a) =>
    find(content, { id: a.contentId }),
  );
  const numAnswers = filteredAnswers.length;
  const getContentUrl = (contentId: string) =>
    (find(content, { id: contentId }) || {}).contentUrl;
  const imageSize = getDefaultImageSize();

  return (
    <div className="flex flex-col gap-4 w-[358px] md:w-[512px]">
      {filteredAnswers.map((answer, index) => (
        <div key={`consultation-answer-${index}`}>
          <div className="flex-grow">
            <img
              src={getContentUrl(answer.contentId)}
              alt={`consultation-content-${index}`}
              style={{
                height: `${imageSize}px`,
                width: `${imageSize}px`,
                objectFit: 'contain',
              }}
            />
          </div>
          <div className="flex py-3 justify-between text-white">
            <span>Description:</span>
            <span>{`${index + 1} / ${numAnswers}`}</span>
          </div>
          <Textarea
            className="w-full h-[24rem] mb-12 bg-white"
            value={answer.visualDescription}
            readOnly
          />
        </div>
      ))}
    </div>
  );
};
