import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { DisplayPartyContent, PartyState } from './types';
import { ECollectionType, EContentState, EReducerState } from '../../app/enum';
import axios from 'axios';
import { config } from '../../config/config';
import { CollectionReqDto } from '../generate/types';
import { apiAxios } from '../../app/axios';
import {
  Collection,
  CollectionNoRelations,
  CollectionRole,
  Content,
  RemoveFromCollectionDto,
} from '../../app/types';
import { isMobile } from 'react-device-detect';
import { RootState } from '../../app/store';
import { ContentVoteDto } from '../vote/types';
import { EContentVote } from '../vote/enum';

const initialState: PartyState = {
  contents: [],
  partyVersion: 0,
  status: EReducerState.IDLE,
  pollStatus: EReducerState.IDLE,
  explorerRoles: [],
};

if (!config.APP_API_URI) {
  throw new Error('APP_API_URI is not defined');
}

export const selectPartyContents = (state: RootState) => state.party.contents;
export const selectExplorerRoles = (state: RootState) =>
  state.party.explorerRoles;

export const partySlice = createSlice({
  name: 'party',
  initialState,
  reducers: {
    setPartyVersion: (state, action: PayloadAction<number>) => {
      state.partyVersion = action.payload;
    },
    setExplorerRoles: (state, action: PayloadAction<CollectionRole[]>) => {
      state.explorerRoles = action.payload;
    },
    addContent: (state, action: PayloadAction<DisplayPartyContent>) => {
      if (
        !state.contents.find(
          (image) => image.content.id === action.payload.content.id,
        )
      ) {
        state.contents.push(action.payload);
        state.contents.sort((a, b) => {
          return b.content.coolRanking! - a.content.coolRanking!;
        });
      }
    },
    removeContent: (state, action: PayloadAction<string>) => {
      state.contents = state.contents.filter(
        (image) => image.content.id !== action.payload,
      );
    },
    updateContentMeta: (state, action: PayloadAction<Content>) => {
      state.contents.forEach((image) => {
        if (image.content.id === action.payload.id) {
          image.content = action.payload;
        }
      });
      state.contents.sort((a, b) => {
        return b.content.coolRanking! - a.content.coolRanking!;
      });
    },
    updateContentVote: (state, action: PayloadAction<ContentVoteDto>) => {
      state.contents.forEach((image) => {
        if (image.content.id === action.payload.contentId) {
          const currentVote = image.content.explorerVote;
          if (image.content.coolRanking == null) {
            console.error('updateContentVote: coolRanking is null');
            return;
          }
          if (currentVote === action.payload.vote) {
            return;
          }
          const voteChangeMap: Record<
            EContentVote,
            Record<EContentVote, number>
          > = {
            [EContentVote.COOL]: {
              [EContentVote.NOT_COOL]: 2,
              [EContentVote.ULTRA_COOL]: -1,
              [EContentVote.NONE]: 1,
            } as Record<EContentVote, number>,
            [EContentVote.NOT_COOL]: {
              [EContentVote.COOL]: -2,
              [EContentVote.ULTRA_COOL]: -3,
              [EContentVote.NONE]: -1,
            } as Record<EContentVote, number>,
            [EContentVote.ULTRA_COOL]: {
              [EContentVote.COOL]: 1,
              [EContentVote.NOT_COOL]: 3,
              [EContentVote.NONE]: 2,
            } as Record<EContentVote, number>,
            [EContentVote.NONE]: {
              [EContentVote.COOL]: 0,
              [EContentVote.NOT_COOL]: 0,
              [EContentVote.ULTRA_COOL]: 0,
              [EContentVote.NONE]: 0,
            } as Record<EContentVote, number>,
          };

          if (
            voteChangeMap[action.payload.vote][currentVote as EContentVote] ==
            null
          ) {
            console.error(
              'updateContentVote: unknown currentVote: ',
              currentVote,
            );
            return;
          }
          image.content.coolRanking +=
            voteChangeMap[action.payload.vote][currentVote as EContentVote];
          image.content.explorerVote = action.payload.vote;
        }
      });
      state.contents.sort((a, b) => {
        return b.content.coolRanking! - a.content.coolRanking!;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPartyCollectionAsync.pending, (state) => {
        state.status = EReducerState.LOADING;
      })
      .addCase(fetchPartyCollectionAsync.fulfilled, (state) => {
        state.status = EReducerState.IDLE;
      })
      .addCase(fetchPartyCollectionAsync.rejected, (state, action) => {
        state.status = EReducerState.FAILED;
        console.error('fetchPartyCollectionAsync: rejected: ', action.error);
      })
      .addCase(voteOnPartyContentAsync.rejected, (state, action) => {
        state.status = EReducerState.FAILED;
        console.error('voteOnPartyContentAsync: rejected: ', action.error);
      })
      .addCase(pollForPartyUpdatesAsync.pending, (state) => {
        state.pollStatus = EReducerState.LOADING;
      })
      .addCase(pollForPartyUpdatesAsync.fulfilled, (state) => {
        state.pollStatus = EReducerState.IDLE;
      })
      .addCase(pollForPartyUpdatesAsync.rejected, (state, action) => {
        state.pollStatus = EReducerState.FAILED;
        console.error('pollForPartyUpdatesAsync: rejected: ', action.error);
      });
  },
});

export const fetchPartyCollectionAsync = createAsyncThunk(
  'party/fetchPartyCollection',
  async (
    collectionId: string,
    { dispatch, getState, rejectWithValue },
  ): Promise<void> => {
    try {
      const reqDto: CollectionReqDto = {
        collectionId: collectionId,
      };
      const getCollectionResponse = await apiAxios.post(
        'collection/get-collection-editor',
        reqDto,
      );
      const collection: Collection = getCollectionResponse.data as Collection;
      if (collection.type !== ECollectionType.PARTY) {
        throw rejectWithValue('Collection is not a party');
      }
      if (collection.partyVersion == null) {
        throw rejectWithValue('Collection has no party version');
      }

      // set party version
      dispatch(setPartyVersion(collection.partyVersion));
      // set explorer roles
      dispatch(setExplorerRoles(collection.explorerCollectionRoles));

      await dispatch(processNewContentAsync(collection.content));
    } catch (err) {
      console.error('fetchPartyCollectionAsync: error: ', err);
      throw rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => {
      const { party: state } = getState() as { party: PartyState };
      if (state.status === EReducerState.LOADING) {
        console.log('fetchPartyCollectionAsync: already loading');
        return false;
      }
    },
  },
);

export const pollForPartyUpdatesAsync = createAsyncThunk(
  'party/pollForPartyUpdates',
  async (collectionId: string, { dispatch, getState, rejectWithValue }) => {
    const pollDto: CollectionReqDto = {
      collectionId: collectionId,
    };
    const versionRequest = await apiAxios.post(
      '/collection/get-collection-no-relations',
      pollDto,
    );
    const simpleCollection: CollectionNoRelations =
      versionRequest.data as CollectionNoRelations;

    const { party: state } = getState() as { party: PartyState };
    if (simpleCollection.partyVersion <= state.partyVersion) {
      return;
    }
    dispatch(setPartyVersion(simpleCollection.partyVersion));

    //otherwise update collection
    const getCollectionResponse = await apiAxios.post(
      'collection/get-collection-editor',
      pollDto,
    );
    const collection: Collection = getCollectionResponse.data as Collection;
    //if items are in state.contents that are in collection.content, romove them from state
    for (const displayObj of state.contents) {
      if (
        collection.content.some(
          (collectionContent) => collectionContent.id === displayObj.content.id,
        )
      ) {
        continue;
      }
      console.log(
        'pollForPartyUpdatesAsync: removing content: ',
        displayObj.content.id,
      );
      dispatch(removeContent(displayObj.content.id));
    }
    //find content in collection.content that is not in state.contents
    const { newContent, existingContent } = collection.content.reduce(
      (
        acc: { newContent: Content[]; existingContent: Content[] },
        contentItem,
      ) => {
        if (
          !state.contents.some(
            (stateContent) => stateContent.content.id === contentItem.id,
          )
        ) {
          acc.newContent.push(contentItem);
        } else {
          acc.existingContent.push(contentItem);
        }
        return acc;
      },
      { newContent: [], existingContent: [] },
    );

    for (const content of existingContent) {
      dispatch(updateContentMeta(content));
    }
    await dispatch(processNewContentAsync(newContent));
  },
  {
    condition: (_, { getState }) => {
      const { party: state } = getState() as { party: PartyState };
      if (
        state.status === EReducerState.LOADING ||
        state.pollStatus === EReducerState.LOADING
      ) {
        console.log('processNewContentAsync: already loading');
        return false;
      }
    },
  },
);

export const processNewContentAsync = createAsyncThunk(
  'party/processNewContent',
  async (contents: Content[], { dispatch, getState, rejectWithValue }) => {
    try {
      // Create a queue of promises
      const promiseQueue = [];

      // Function to process a content fetch
      async function processContent(content: Content) {
        if (content.contentState !== EContentState.GENERATED) {
          console.log('content not generated: ', content.id);
          return null;
        }

        const imageResponse = await axios.get(content.contentUrl!, {
          responseType: 'blob',
        });
        const blob = new Blob([imageResponse.data], { type: 'image/png' });
        const dataUrl: string = URL.createObjectURL(blob);
        const partyContent: DisplayPartyContent = {
          content: content,
          imageData: dataUrl,
        };
        dispatch(addContent(partyContent));
      }

      //start with 5 on mobile
      const chunkSize = isMobile ? 5 : contents.length;

      // Start the first chunkSize promises
      for (let i = 0; i < chunkSize; i++) {
        const content = contents.shift();
        if (content) {
          promiseQueue.push(processContent(content));
        }
      }

      // As each promise resolves, start a new one
      while (contents.length > 0) {
        await Promise.race(promiseQueue);
        const index: number = promiseQueue.findIndex((p) =>
          Promise.resolve(p)
            .then(() => true)
            .catch(() => false),
        );
        const content = contents.shift();
        if (content) {
          promiseQueue[index] = processContent(content);
        }
      }
    } catch (error) {
      console.error('processNewContentAsync: error: ', error);
      throw rejectWithValue(error);
    }
  },
);

export const voteOnPartyContentAsync = createAsyncThunk(
  'party/voteOnPartyContent',
  async (voteDto: ContentVoteDto, { dispatch, getState, rejectWithValue }) => {
    try {
      if (!voteDto.collectionId || !voteDto.collectionType) {
        throw rejectWithValue(
          'voteOnPartyContentAsync: voteDto.collectionId or voteDto.collectionType is null',
        );
      }
      dispatch(updateContentVote(voteDto));
      const voteResonse = await apiAxios.post(
        '/content/vote-on-content',
        voteDto,
      );
      const returnContent = voteResonse.data as Content;
      dispatch(updateContentMeta(returnContent));
      console.log('finished voting');
    } catch (err: unknown) {
      console.error('voteOnPartyContentAsync: error: ', err);
      const message = (err as Error).message;
      throw rejectWithValue(message);
    }
  },
);

export const removePartyContentAsync = createAsyncThunk(
  'party/removePartyContent',
  async (
    removeDto: RemoveFromCollectionDto,
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      dispatch(removeContent(removeDto.contentId));
      await apiAxios.post('/collection/remove-from-collection', removeDto);
    } catch (err) {
      console.error('removePartyContentAsync: error: ', err);
      throw rejectWithValue(err);
    }
  },
);

export const {
  addContent,
  removeContent,
  updateContentMeta,
  setPartyVersion,
  setExplorerRoles,
  updateContentVote,
} = partySlice.actions;
export const partyReducer = partySlice.reducer;
