import {
  ArrowUpTrayIcon,
  Cog8ToothIcon,
  CubeIcon,
  FolderOpenIcon,
  FolderPlusIcon,
  InformationCircleIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import { removeImageAsync } from '../features/generate/generate-images-slice';
import { useAppDispatch } from '../app/hooks';
import { ImageSelectionData, handleZipDownload } from '../utils';
import { UploadForm } from '../features/generate/UploadForm';
import { useState } from 'react';
import { Button } from 'flowbite-react';
import { isEmpty } from 'lodash';
import { Glossary } from './Glossary';
import { SettingsModal } from './SettingsModal';
import { ECollectionModalType } from '../features/generate/enum';
import { CollectionModal } from './CollectionModal';
import { ModalBase } from './ModalBase';

interface InstrumentLeftDrawerProps {
  selectedImages: ImageSelectionData[];
  clearSelectionCallback: () => void;
}

export const InstrumentLeftDrawer = ({
  selectedImages,
  clearSelectionCallback,
}: InstrumentLeftDrawerProps) => {
  const dispatch = useAppDispatch();
  const [collectionsOpen, setCollectionsOpen] = useState<ECollectionModalType>(
    ECollectionModalType.NONE,
  );
  const [uploadOpen, setUploadOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [glossaryOpen, setGlossaryOpen] = useState(false);

  return (
    <>
      <div className="w-full h-auto grid md:grid-cols-2 md:w-[6rem] md:h-[13rem] bg-instrument-body md:bg-instrument-leftdrawer md:rounded-l-2xl">
        <div className="flex flex-row md:flex-col justify-between items-center px-4 py-[14px] md:p-2 text-white">
          <div
            className="active:scale-75 transition-transform instrument-button-round bg-instrument-leftdrawer-delete hover:bg-white hover:text-instrument-leftdrawer-delete"
            title="Disappear"
            onClick={() => {
              selectedImages.forEach((imageData) => {
                const { displayImage, displayImageList } = imageData;
                dispatch(
                  removeImageAsync({
                    dList: displayImageList,
                    image: displayImage,
                  }),
                );
              });
              clearSelectionCallback();
            }}
          >
            <TrashIcon />
          </div>
          <div
            className="active:scale-75 transition-transform instrument-button-round bg-instrument-leftdrawer-add hover:bg-white hover:text-instrument-leftdrawer-add"
            title="Add to Collection"
            onClick={() => {
              if (!isEmpty(selectedImages)) {
                setCollectionsOpen(ECollectionModalType.ADD_TO_COLLECTION);
              }
            }}
          >
            <FolderPlusIcon />
          </div>
          <div
            className="active:scale-75 transition-transform instrument-button-round bg-instrument-leftdrawer-open hover:bg-white hover:text-instrument-leftdrawer-open"
            title="Load Collection"
            onClick={() =>
              setCollectionsOpen(ECollectionModalType.CHANGE_COLLECTION)
            }
          >
            <FolderOpenIcon />
          </div>
          <div
            className="active:scale-75 transition-transform instrument-button-round bg-instrument-leftdrawer-share hover:bg-white hover:text-instrument-leftdrawer-share"
            title="Feed Nilor"
            onClick={() => setUploadOpen(true)}
          >
            <ArrowUpTrayIcon />
          </div>
          <div
            className="active:scale-75 transition-transform instrument-button-round bg-instrument-leftdrawer-package hover:bg-white hover:text-instrument-leftdrawer-package"
            title="Package"
            onClick={() => {
              if (!isEmpty(selectedImages)) {
                handleZipDownload(
                  selectedImages.map(
                    (img) => img.displayImage.content?.contentUrl,
                  ),
                  () => {},
                  () => clearSelectionCallback(),
                );
              }
            }}
          >
            <CubeIcon />
          </div>
          <div
            className="md:hidden active:scale-75 transition-transform instrument-button-round bg-instrument-leftdrawer-add hover:bg-white hover:text-instrument-leftdrawer-share"
            title="Settings"
            onClick={() => setSettingsOpen(true)}
          >
            <Cog8ToothIcon />
          </div>
          <div
            className="md:hidden active:scale-75 transition-transform instrument-button-round bg-instrument-leftdrawer-add hover:bg-white hover:text-instrument-leftdrawer-share"
            title="Feed Nilor"
            onClick={() => setGlossaryOpen(true)}
          >
            <InformationCircleIcon />
          </div>
        </div>
      </div>
      <ModalBase
        title="Feed Nilor"
        body={<UploadForm onComplete={() => setUploadOpen(false)} />}
        footer={
          <Button
            className="w-full md:w-1/2"
            color="dark"
            onClick={() => setUploadOpen(false)}
          >
            Close
          </Button>
        }
        show={uploadOpen}
        onClose={() => setUploadOpen(false)}
      />
      {/* Modal for Glossary */}
      <ModalBase
        title="Glossary"
        body={<Glossary />}
        footer={
          <Button
            className="w-full md:w-1/2"
            color="dark"
            onClick={() => setGlossaryOpen(false)}
          >
            Close
          </Button>
        }
        show={glossaryOpen}
        onClose={() => setGlossaryOpen(false)}
      />
      {settingsOpen && <SettingsModal onClose={() => setSettingsOpen(false)} />}
      {collectionsOpen !== ECollectionModalType.NONE && (
        <CollectionModal
          modalType={collectionsOpen}
          selectedImagesData={selectedImages}
          clearSelectionCallback={clearSelectionCallback}
          onClose={() => setCollectionsOpen(ECollectionModalType.NONE)}
        />
      )}
    </>
  );
};
