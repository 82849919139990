import { ESAIImageShape } from '../features/generate/enum';
import { getDefaultImageSize } from '../utils';

export const migrations = {
  1: (state: any) => {
    return {
      ...state,
      userPrefs: {
        ...state.userPrefs,
        imageSize: getDefaultImageSize(),
      },
    };
  },
  2: (state: any) => {
    return {
      ...state,
      userPrefs: {
        ...state.userPrefs,
        parentInfluence: 0,
      },
    };
  },
  3: (state: any) => {
    return {
      ...state,
      userPrefs: {
        ...state.userPrefs,
        imageShape: ESAIImageShape.SQUARE,
      },
    };
  },
  4: (state: any) => {
    return {
      ...state,
      userPrefs: {
        ...state.userPrefs,
        viewImageBackground: '#ffffff',
      },
    };
  },
};
