import { ERole } from '../../enum';
export interface SiweNonceCredential {
  nonce?: string;
  address: string;
}

export interface SiweVerifyCredential {
  message: string;
  signature: string;
  nonce?: string;
}

export interface SiweCredentialPayload {
  nonceCredentials: SiweNonceCredential;
  verifyCredentials: SiweVerifyCredential;
  token: string;
  role?: ERole;
}

export function isSiweNonceCredential(arg: any): arg is SiweNonceCredential {
  return arg.nonce !== undefined && arg.address !== undefined;
}

export function isSiweVerifyCredential(arg: any): arg is SiweVerifyCredential {
  return arg.message !== undefined && arg.signature !== undefined;
}
