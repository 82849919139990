import { Dialog, Switch, Transition } from '@headlessui/react';
import { ModalInfo } from '../features/generate/generate-images';
import { Fragment, useEffect, useRef, useState } from 'react';
import { get } from 'lodash';
import { MaskEditor, toMask } from 'react-mask-editor';
import { Textarea } from 'flowbite-react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  generateSessionImagesAsync,
  removeImageAsync,
} from '../features/generate/generate-images-slice';
import { GenerateImageDto } from '../features/generate/types';
import { EGenerateImageType } from '../features/generate/enum';
import { isMobile } from 'react-device-detect';
import {
  selectImageShape,
  selectViewImageBackground,
  setViewImageBackground,
} from '../features/userPrefs/userPrefs-slice';
import { ArrowDownTrayIcon, TrashIcon } from '@heroicons/react/20/solid';

type useStateModalInfo = React.Dispatch<React.SetStateAction<ModalInfo>>;
export type GenerateImageModalProps = {
  openModal: ModalInfo;
  setOpenModal: useStateModalInfo;
  clearSelectionCallback: () => void;
};

export const ViewImageModal = ({
  openModal,
  setOpenModal,
}: GenerateImageModalProps) => {
  const canvas = useRef<HTMLCanvasElement>();
  const [paintMode, setPaintMode] = useState(false);
  const [cursorSize, setCursorSize] = useState(10);
  const [opacity, setOpacity] = useState(75);
  const [paintPrompt, setPaintPrompt] = useState('');
  const [editPromptMode, setEditPromptMode] = useState(false);
  const [originalPrompt, setOriginalPrompt] = useState('');
  const [editedPrompt, setEditedPrompt] = useState('');
  const imageShape = useAppSelector(selectImageShape);
  const backgroundColor = useAppSelector(selectViewImageBackground);
  const dispatch = useAppDispatch();

  const closeModal = () => {
    setPaintMode(false);
    setPaintPrompt('');
    setEditPromptMode(false);
    setOriginalPrompt('');
    setEditedPrompt('');
    setOpenModal({ isOpen: false, displayImages: openModal.displayImages });
  };
  const image = get(openModal, 'displayImages[0]');
  const imageSrc = get(openModal, 'displayImages[0].imageData', '');
  const imageId = get(openModal, 'displayImages[0].content.id', '');
  const colNum = get(openModal, 'displayImages[0].content.colNum', 1);
  const isReference = get(openModal, 'displayImages[0].content.isReference');

  useEffect(() => {
    const prompt = get(openModal, 'displayImages[0].content.prompt', '');
    if (prompt && originalPrompt !== prompt) {
      setEditedPrompt(prompt);
    }
  }, [openModal]);

  return (
    <Transition.Root show={openModal.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full object-contain items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-auto w-scr max-w-screen bg-stone-600 rounded-lg text-left shadow-xl transition-all">
                <>
                  <div
                    className="mx-auto flex object-contain max-w-full items-center justify-center p-2 md:p-4"
                    style={{ backgroundColor }}
                  >
                    <img
                      src={imageSrc}
                      alt="fullsize"
                      className={
                        paintMode
                          ? 'absolute'
                          : 'max-w-full max-h-screen object-contain'
                      }
                    />
                    {paintMode && (
                      <MaskEditor
                        src={imageSrc}
                        // @ts-ignore
                        canvasRef={canvas}
                        maskOpacity={opacity / 100}
                        maskColor={'#ffffff'}
                        cursorSize={cursorSize}
                      />
                    )}
                  </div>
                  <div className="flex justify-between items-center gap-3 w-full mt-4 px-4">
                    <a href={imageSrc} download>
                      <div
                        className="active:scale-75 transition-transform instrument-button-round bg-stone-700 text-white hover:bg-white hover:text-black"
                        title="Download"
                      >
                        <ArrowDownTrayIcon />
                      </div>
                    </a>
                    <div
                      className="active:scale-75 transition-transform instrument-button-round bg-stone-700 text-red-400 hover:bg-white hover:text-red-400"
                      title="Disappear"
                      onClick={() => {
                        dispatch(
                          removeImageAsync({
                            dList: openModal.imageList!,
                            image: image!,
                          }),
                        );
                        closeModal();
                      }}
                    >
                      <TrashIcon />
                    </div>
                  </div>
                  {/* paint controls */}
                  <div className="flex justify-center items-center gap-3 w-full mt-4">
                    <div className="flex gap-3">
                      {!isMobile && <span className="text-white">Paint</span>}
                      {!isMobile && (
                        <Switch
                          checked={paintMode}
                          onChange={() => setPaintMode(!paintMode)}
                          disabled={editPromptMode}
                          className={`${
                            paintMode ? 'bg-indigo-600' : 'bg-gray-200'
                          } ${
                            editPromptMode
                              ? 'cursor-not-allowed'
                              : 'cursor-pointer'
                          } relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                        >
                          <span
                            aria-hidden="true"
                            className={`${
                              paintMode ? 'translate-x-5' : 'translate-x-0'
                            } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                          />
                        </Switch>
                      )}
                      <span className="text-white">Edit Prompt</span>
                      <Switch
                        checked={editPromptMode}
                        onChange={() => setEditPromptMode(!editPromptMode)}
                        disabled={paintMode}
                        className={`${
                          editPromptMode ? 'bg-indigo-600' : 'bg-gray-200'
                        } ${
                          paintMode ? 'cursor-not-allowed' : 'cursor-pointer'
                        } relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                      >
                        <span
                          aria-hidden="true"
                          className={`${
                            editPromptMode ? 'translate-x-5' : 'translate-x-0'
                          } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                        />
                      </Switch>
                      <span className="text-white">Background</span>
                      <input
                        type="color"
                        value={backgroundColor}
                        onChange={(e) =>
                          dispatch(setViewImageBackground(e.target.value))
                        }
                        className="rounded-lg"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 items-center py-4">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-300"
                    >
                      {`ID: ${imageId}`}
                    </Dialog.Title>
                    <p className="text-base font-mono text-white px-3 max-w-4xl">
                      {get(openModal, 'displayImages[0].content.prompt', '')}
                    </p>
                    {paintMode && (
                      <div className="flex flex-col gap-2 w-1/2">
                        <span className="text-white">Cursor size</span>
                        <input
                          id="minmax-range"
                          type="range"
                          min={4}
                          max={200}
                          value={cursorSize}
                          onChange={(e) =>
                            setCursorSize(parseInt(e.target.value))
                          }
                          className="h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                        />
                        <span className="text-white">Opacity</span>
                        <input
                          id="minmax-range"
                          type="range"
                          min={1}
                          max={100}
                          value={opacity}
                          onChange={(e) => setOpacity(parseInt(e.target.value))}
                          className="h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                        />
                        <span className="text-white">Prompt</span>
                        <Textarea
                          className="w-full h-[8rem]"
                          value={paintPrompt}
                          onChange={(e) => setPaintPrompt(e.target.value)}
                        />
                        <button
                          type="button"
                          className="flex justify-center items-center rounded-md w-[6rem] bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
                          onClick={() => {
                            if (canvas.current) {
                              const maskBase64 = (toMask(canvas.current) || '')
                                .toString()
                                .replace('data:', '')
                                .replace(/^.+,/, '');

                              const generateDto: GenerateImageDto = {
                                type: EGenerateImageType.PAINT,
                                prompt: paintPrompt,
                                maskBase64: maskBase64,
                                parentId: imageId,
                                colNum: colNum,
                              };
                              dispatch(generateSessionImagesAsync(generateDto));
                              closeModal();
                            } else {
                              console.error(
                                'no current canvas on paint summon',
                              );
                            }
                          }}
                        >
                          Summon
                        </button>
                      </div>
                    )}
                    {editPromptMode && (
                      <div className="flex flex-col gap-2 w-full md:w-1/2">
                        <Textarea
                          className="w-full h-[8rem]"
                          value={editedPrompt}
                          onChange={(e) => setEditedPrompt(e.target.value)}
                        />
                        <button
                          type="button"
                          className="flex justify-center items-center rounded-md w-[6rem] bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
                          onClick={() => {
                            const generateDto: GenerateImageDto = {
                              type: EGenerateImageType.SUMMON,
                              prompt: editedPrompt,
                              parentId: imageId,
                              colNum,
                              imageShape,
                              dList: openModal.imageList,
                            };
                            dispatch(generateSessionImagesAsync(generateDto));
                            closeModal();
                          }}
                        >
                          Summon
                        </button>
                      </div>
                    )}
                    {isReference && (
                      <span className="text-white font-bold">[UPLOADED]</span>
                    )}
                  </div>
                </>
                <div className="p-4">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => closeModal()}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
