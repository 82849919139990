import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createMigrate,
} from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import autoMergeLevel1 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1';
import counterReducer from '../features/counter/counterSlice';
import { gauthReducer } from '../features/auth/gauth/gauth-slice';
import { siweReducer } from '../features/auth/siwe-auth/siwe-auth-slice';
import { ethReducer } from '../features/web3/eth/eth-slice';
import { voteReducer } from '../features/vote/vote-slice';
import { moderationReducer } from '../features/moderation/moderation-slice';
import { voteLeaderReducer } from '../features/vote/vote-leaders-slice';
import { generateImagesReducer } from '../features/generate/generate-images-slice';
import { partyReducer } from '../features/party/party-slice';
import userPrefsReducer from '../features/userPrefs/userPrefs-slice';
import { migrations } from './migrations';

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel1,
  blacklist: [
    'eth',
    'vote',
    'gauth',
    'siwe',
    'moderation',
    'voteLeader',
    'generateImages',
    'party',
  ],
  version: 4, // Set the current version of your state
  migrate: createMigrate(migrations, { debug: false }), // Apply migrations
};

const siwePersistConfig = {
  key: 'siwe',
  storage: storage,
  blacklist: ['status', 'isLoggedIn', 'token'],
};
const siwePersist = persistReducer(siwePersistConfig, siweReducer);

const gauthPersistConfig = {
  key: 'gauth',
  storage: storage,
  blacklist: ['status'],
};
const gauthPersist = persistReducer(gauthPersistConfig, gauthReducer);

const combinePersistentReducers = combineReducers({
  counter: counterReducer,
  gauth: gauthPersist,
  siwe: siwePersist,
  eth: ethReducer,
  vote: voteReducer,
  voteLeader: voteLeaderReducer,
  moderation: moderationReducer,
  generateImages: generateImagesReducer,
  party: partyReducer,
  userPrefs: userPrefsReducer,
});

const persistentReducer = persistReducer(
  persistConfig,
  combinePersistentReducers as any,
);

export const store = configureStore({
  reducer: persistentReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      //false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredPaths: ['generateImages'],
        ignoredActionPaths: ['payload.dList', 'meta.arg.dList'],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
