import { Dispatch, FC } from 'react';
import { GenerateInstrumentInfo, GenerateImageDto } from './types';
import { EGenerateImageType, ESAIImageShape } from './enum';
import { ImageSelectionData } from '../../utils';

interface ExploreButtonProps {
  onExploreClick: (() => void)[];
  buttonsInfo: GenerateInstrumentInfo[];
}

export const ExploreButtons: FC<ExploreButtonProps> = ({
  onExploreClick,
  buttonsInfo,
}) => {
  if (onExploreClick.length !== buttonsInfo.length) {
    throw new Error('onExploreClick and buttonsInfo must have the same length');
  }

  return (
    // <div className="justify-center grid grid-flow-col pb-2 gap-2 items-center mx-auto">
    <div className="justify-center flex flex-wrap pb-2 gap-2 items-center mx-auto">
      {buttonsInfo.map((buttonInfo, index) => (
        <button
          key={index}
          className="transform active:scale-75 transition-transform w-fit rounded-md bg-slate-500 px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-slate-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
          onClick={() => onExploreClick[index]()}
        >
          {buttonInfo.display}
        </button>
      ))}
    </div>
  );
};

export function batchExploreFunction(
  exploreRowInfo: GenerateInstrumentInfo,
  imageData: ImageSelectionData[],
  imageShape: ESAIImageShape,
  parentInfluence: number,
  dispatch: Dispatch<any>,
  generateSessionImagesAsync: (generateDto: GenerateImageDto) => void,
): () => void {
  return () => {
    imageData.map((imageData) => {
      const { displayImageList, displayImage } = imageData;
      const generateDto: GenerateImageDto = {
        prompt: exploreRowInfo.prephixPhrase + displayImage.content.prompt!,
        systemContext: exploreRowInfo.context,
        parentId: displayImage.content.id,
        colNum: displayImage.colNum,
        dList: displayImageList,
        type: EGenerateImageType.INSTRUMENT,
        parentInfluence,
        imageShape,
      };
      dispatch(generateSessionImagesAsync(generateDto));
    });
  };
}
