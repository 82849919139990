import { ERole } from '../../features/auth/enum';
export interface ApiJwt {
  token: string;
  role?: ERole;
  statusCode?: number;
}

export function isApiJwt(arg: any): arg is ApiJwt {
  return arg.token !== undefined;
}
