export enum EContentType {
  NONE = 'NONE',
  PROMPT = 'PROMPT',
  PROMPT_IMAGE = 'PROMPT_IMAGE',
  CHAT_REQUEST = 'CHAT_REQUEST',
  CHAT_REPLY = 'CHAT_REPLY',
  POTION_IMAGE = 'POTION_IMAGE',
  POTION_MUTATE_IMAGE = 'POTION_MUTATE_IMAGE',
  IMAGE_IMAGE = 'IMAGE_IMAGE',
}
