type HeroiconType = React.ForwardRefExoticComponent<
  React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> &
    React.RefAttributes<SVGSVGElement>
>;

interface IconWrapperProps {
  icon: string | HeroiconType;
  alt: string;
  [key: string]: any; // Additional props
}

export const IconWrapper: React.FC<IconWrapperProps> = ({
  icon,
  alt,
  ...otherProps
}) => {
  if (typeof icon === 'string') {
    // Render normal SVG using the provided icon string
    return <img src={icon} alt={alt} {...otherProps} />;
  } else if (typeof icon === 'object') {
    // Render Heroicon component
    const HeroiconComponent = icon as HeroiconType;
    return <HeroiconComponent aria-hidden="true" {...otherProps} />;
  } else {
    // Handle unsupported icon types or provide a default fallback
    return <div>Unsupported icon</div>;
  }
};
