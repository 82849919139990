import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EthState } from './types';
import { RootState } from '../../../app/store';

const initialState: EthState = {
  isCKHolder: false,
};

export const ethSlice = createSlice({
  name: 'eth',
  initialState,
  reducers: {
    setIsCkHolder: (state, action: PayloadAction<boolean>) => {
      state.isCKHolder = action.payload;
      console.log(
        'ethSlice: setIsCkHolder: state.isCKHolder: ',
        state.isCKHolder,
      );
    },
  },
});

export const selectIsCkHolder = (state: RootState) => state.eth.isCKHolder;

export const { setIsCkHolder } = ethSlice.actions;
export const ethReducer = ethSlice.reducer;
