import { useState } from 'react';
import { default as dial } from '../images/dial.svg';
import { clamp } from 'lodash';

interface InstrumentDialProps {
  parentInfluence: number;
  onValueChange: (value: number) => void;
  hide?: boolean;
}

export const InstrumentDial = ({
  parentInfluence,
  onValueChange,
  hide,
}: InstrumentDialProps) => {
  const [value, setValue] = useState(parentInfluence);
  const [isDragging, setIsDragging] = useState(false);
  const [lastY, setLastY] = useState(0);

  if (hide) {
    return null;
  }

  const degValue = (value / 100) * 120 + 30;

  return (
    <div className="h-[208px] w-[208px]">
      <img
        src={dial}
        alt="dial"
        draggable={false}
        className="cursor-pointer bg-blue select-none"
        style={{
          transform: `rotate(${degValue}deg)`,
          transformOrigin: 'center center',
        }}
        onMouseDown={(e) => {
          setIsDragging(true);
          setLastY(e.clientY);
        }}
        onMouseUp={() => {
          setIsDragging(false);
          onValueChange(value);
        }}
        onMouseOut={() => {
          setIsDragging(false);
          onValueChange(value);
        }}
        onMouseMove={(e) => {
          if (isDragging) {
            const valueChange = e.clientY < lastY ? -1 : 1;
            const newValue = clamp(value + valueChange, 0, 100);
            setLastY(e.clientY);
            setValue(newValue);
          }
        }}
      />
    </div>
  );
};
