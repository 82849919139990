import { get, groupBy } from 'lodash';
import { apiAxios } from '../app/axios';
import { Loadout } from '../app/types/instrument';
import { ModifiedSettings } from './settings';

export const getInfluenceColor = (influence: number) => {
  if (influence < 20) {
    return 'bg-lime-400';
  }
  if (influence < 40) {
    return 'bg-yellow-300';
  }
  if (influence < 60) {
    return 'bg-amber-500';
  }
  if (influence < 80) {
    return 'bg-orange-500';
  }
  return 'bg-red-700';
};

export interface InstrumentsPayload {
  instrumentId: string;
  instrument: {
    instrumentInfo: {
      display: string;
      context: string;
      prephixPhrase: string;
    }[];
  };
  name: string;
}

export const mapInstrumentsToLoadouts = (
  instruments: InstrumentsPayload[],
): Loadout[] => {
  return (instruments || []).map((instrument) => ({
    id: instrument.instrumentId,
    name: instrument.name,
    directions: instrument.instrument.instrumentInfo?.map((info, index) => ({
      id: `${instrument.instrumentId}-${index}`,
      label: info.display,
      context: info.context,
      func: info.prephixPhrase,
      callback: () => {},
    })),
  }));
};

export interface CreateInstrumentPayload {
  name: string;
  instrument: {
    instrumentInfo: {
      display: string;
      context: string;
      prephixPhrase: string;
    }[];
  };
  collectionId: string;
}

export interface UpdateInstrumentPayload extends CreateInstrumentPayload {
  instrumentId: string;
}

export const mapDiffToInstrumentPayloads = (
  loadouts: Loadout[],
  diff: ModifiedSettings,
  collectionId: string,
): {
  activeLoadoutId: string;
  createLoadout?: CreateInstrumentPayload[];
  updateLoadout?: UpdateInstrumentPayload[];
  deleteLoadout?: string[];
} => {
  const groupedLoadouts = groupBy(diff.loadouts, 'action');

  return {
    activeLoadoutId: diff.activeLoadoutId,
    createLoadout:
      groupedLoadouts.create?.map((loadout) => ({
        name: loadout.name,
        instrument: {
          instrumentInfo: loadout.directions.map((direction) => ({
            display: direction.label,
            context: direction.context,
            prephixPhrase: direction.func,
          })),
        },
        collectionId: collectionId,
      })) || [],
    updateLoadout:
      groupedLoadouts.update?.map((loadout) => ({
        instrumentId: loadout.id,
        name:
          loadout.name || loadouts.find((l) => l.id === loadout.id)?.name || '',
        instrument: {
          instrumentInfo: loadouts
            .find((l) => l.id === loadout.id)
            ?.directions.map((direction, index) => {
              const directionId = `${loadout.id}-${index}`;
              const newDirection = get(
                loadout,
                `directions[${directionId}]`,
                {},
              );
              return {
                display: newDirection.label || direction.label,
                context: newDirection.context || direction.context,
                prephixPhrase: newDirection.func || direction.func,
              };
            }),
        },
      })) || [],
    deleteLoadout: groupedLoadouts.delete?.map((loadout) => loadout.id) || [],
  };
};

export const fetchInstruments = (): Promise<any> =>
  apiAxios
    .post('/instrument/get-explorer-instruments')
    .then((response) => response.data);

export const updateActiveLoadout = (instrumentId: string): Promise<any> =>
  apiAxios
    .post('/session/generate/set-session-instrument', { instrumentId })
    .then((response) => response.data);

export const createInstrument = (
  instrument: CreateInstrumentPayload,
): Promise<any> =>
  apiAxios
    .post('/instrument/create-instrument', instrument)
    .then((response) => response.data);

export const deleteInstrument = (instrumentId: string): Promise<any> =>
  apiAxios
    .post('/instrument/delete-explorer-instrument', { instrumentId })
    .then((response) => response.data);

export const updateInstrument = (
  instrument: UpdateInstrumentPayload,
): Promise<any> =>
  apiAxios
    .post('/instrument/update-instrument', instrument)
    .then((response) => response.data);
