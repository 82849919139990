import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModerationState } from './moderation-state';
import { EReducerState } from '../../app/enum';
import { apiAxios } from '../../app/axios';
import { ContentRatingDto } from './types';
import { Content } from '../../app/types';

const initialState: ModerationState = {
  status: EReducerState.IDLE,
};

export const moderationSlice = createSlice({
  name: 'moderation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateContentRatingAsync.pending, (state) => {
        state.status = EReducerState.LOADING;
      })
      .addCase(
        updateContentRatingAsync.fulfilled,
        (state, action: PayloadAction<Content>) => {
          state.status = EReducerState.IDLE;
          const content: Content = action.payload;
          console.log(
            'moderationSlice: updateContentRatingAsync.fulfilled: content: ',
            content,
          );
        },
      )
      .addCase(updateContentRatingAsync.rejected, (state) => {
        state.status = EReducerState.FAILED;
        console.error('moderationSlice: updateContentRatingAsync.rejected');
      });
  },
});

export const updateContentRatingAsync = createAsyncThunk(
  'moderation/updateContentRatingAsync',
  async (ratingDto: ContentRatingDto) => {
    console.log('updateContentRatingAsync: ratingDto: ', ratingDto);
    const response = await apiAxios.post(
      '/content/update-content-rating',
      ratingDto,
    );
    const payload: Content = response.data;
    return payload;
  },
);

export const moderationReducer = moderationSlice.reducer;
