import React, { useState } from 'react';
import { Switch } from '@headlessui/react';
import sea_monster from '../../images/sea_monster.svg';
import { default as logo } from '../../images/logo.svg';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

type useStateToggleInfo = React.Dispatch<React.SetStateAction<boolean>>;
type toggleProps = {
  enabled: boolean;
  setEnabled: useStateToggleInfo;
};

export default function Toggle({ enabled, setEnabled }: toggleProps) {
  //const [enabled, setEnabled] = useState(false);

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={classNames(
          enabled ? 'bg-red-400' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-200 focus:ring-offset-2',
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3 text-sm">
        <div className="text-sm font-bold font-mono tracking-tight text-red-400">
          {enabled ? 'DARK MODE 👻' : 'DARK MODE'}
        </div>
      </Switch.Label>
    </Switch.Group>
  );
}

export function SpinningMonster() {
  const [enabled, setEnabled] = useState(false);
  return (
    <div className="relative isolate pt-14">
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-13rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 translate-y-60 rotate-[30deg] bg-gradient-to-tr from-[#9fff6f] to-[#ffb6f3] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="py-24 sm:py-32 lg:pb-40 ">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 pt-14">
          <div className="mx-auto max-w-2xl text-center content-center">
            <div className="flex justify-center items-center pb-10">
              <Toggle enabled={enabled} setEnabled={setEnabled} />
            </div>
            <h1 className="text-4xl font-bold font-mono tracking-tight text-red-400 sm:text-2xl">
              {'SIGN IN <(-_-)>'}
            </h1>
            {enabled && (
              <div className="animate-bounce-slow flex justify-center pt-10">
                <div className="animate-spin-slow">
                  <img
                    src={sea_monster}
                    alt="App screenshot"
                    width={320 / 2}
                    height={320 / 2}
                  />
                </div>
              </div>
            )}
            {!enabled && (
              <div className="animate-bounce-slow flex justify-center pt-10">
                <img
                  src={logo}
                  alt="App screenshot"
                  width={320 / 2}
                  height={320 / 2}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 translate-y-5 bg-gradient-to-tr from-[#9fff6f] to-[#ffb6f3] opacity-20 sm:left-[calc(50%+1rem)] sm-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </div>
  );
}
