import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { VoteState } from './vote-state';
import { RootState } from '../../app/store';
import { EReducerState } from '../../app/enum';
import { config } from '../../config/config';
import { Content } from '../../app/types';
import axios from 'axios';
import { selectSiweToken } from '../auth/siwe-auth/siwe-auth-slice';
import { apiAxios } from '../../app/axios';
import { SiweState } from '../auth/siwe-auth/types';
import { DisplayVoteContent, ContentVoteDto, GetVoteContentDto } from './types';
import { EVoteDisplayState, EVoteRequestType } from './enum';
import Emitter from '../../app/emitter';

const initialState: VoteState = {
  images: [],
  unconfirmedVoteIDs: [],
  errorVotedIDs: [],
  index: 0,
  status: EReducerState.IDLE,
  fetchVoteImagesState: EReducerState.IDLE,
  voteOnImagesState: EReducerState.IDLE,
  voteDisplayState: EVoteDisplayState.EMPTY,
  voteRequestState: EVoteRequestType.CURATED,
  endOfCuratedContent: false,
};

if (!config.APP_API_URI) {
  throw new Error('APP_API_URI is not defined');
}

export const selectIndex = (state: RootState) => state.vote.index;
export const selectImages = (state: RootState) => state.vote.images;
export const selectState = (state: RootState) => state.vote.status;
export const selectVoteDisplayState = (state: RootState) =>
  state.vote.voteDisplayState;
export const selectVoteRequestState = (state: RootState) =>
  state.vote.voteRequestState;
export const selectEndOfCuratedContent = (state: RootState) =>
  state.vote.endOfCuratedContent;

export const voteSlice = createSlice({
  name: 'vote',
  initialState,
  reducers: {
    storeImages: (state, action: PayloadAction<DisplayVoteContent[]>) => {
      state.images = action.payload;
    },
    nextImage: (state) => {
      if (state.voteDisplayState === EVoteDisplayState.EMPTY) {
        return;
      }

      //if index is in array bounds, at images content id to unconfirmed vote ids
      if (state.index < state.images.length) {
        const contentId: string = state.images[state.index].content.id;
        state.unconfirmedVoteIDs.push(contentId);
      }

      if (state.index + 1 > state.images.length - 1) {
        //set image display state to end of array
        state.index = state.images.length - 1;
        state.voteDisplayState = EVoteDisplayState.EMPTY;
      } else {
        state.index = state.index + 1;
      }
    },
    addImage: (state, action: PayloadAction<DisplayVoteContent>) => {
      if (state.voteDisplayState === EVoteDisplayState.EMPTY) {
        state.voteDisplayState = EVoteDisplayState.ACTIVE;
      }
      state.images.push(action.payload);
    },
    markIdAsError: (state, action: PayloadAction<string>) => {
      state.errorVotedIDs.push(action.payload);
    },
    setVoteDisplayState: (state, action: PayloadAction<EVoteDisplayState>) => {
      state.voteDisplayState = action.payload;
    },
    setVoteRequestState: (state, action: PayloadAction<EVoteRequestType>) => {
      state.voteRequestState = action.payload;
    },
    setEndOfCuratedContent: (state, action: PayloadAction<boolean>) => {
      state.endOfCuratedContent = action.payload;
    },
    clearImages: (state) => {
      let spliceIndex: number = state.index;
      if (state.voteDisplayState === EVoteDisplayState.EMPTY) {
        //it's empty, so clear last image to be voted on
        spliceIndex += 1;
      }
      const toClear: DisplayVoteContent[] = state.images.splice(0, spliceIndex);
      for (const content of toClear) {
        URL.revokeObjectURL(content.imageData);
        //if content id is in unconfirmed vote ids, remove it
        const index: number = state.unconfirmedVoteIDs.indexOf(
          content.content.id,
        );
        if (index !== -1) {
          state.unconfirmedVoteIDs.splice(index, 1);
        }
      }
      state.index = 0;
    },
    resetVoteState: (state) => {
      //revoke all urls
      for (const content of state.images) {
        URL.revokeObjectURL(content.imageData);
      }
      state.images = [];
      state.unconfirmedVoteIDs = [];
      state.errorVotedIDs = [];
      state.index = 0;
      state.status = EReducerState.IDLE;
      state.fetchVoteImagesState = EReducerState.IDLE;
      state.voteOnImagesState = EReducerState.IDLE;
      state.voteDisplayState = EVoteDisplayState.EMPTY;
      state.endOfCuratedContent = false;
      state.voteRequestState = EVoteRequestType.CURATED;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVoteImagesAsync.pending, (state) => {
        state.fetchVoteImagesState = EReducerState.LOADING;
        console.log(
          'fetchVoteImagesAsync.pending: state.fetchVoteImageState: ',
          state.fetchVoteImagesState,
        );
      })
      .addCase(fetchVoteImagesAsync.fulfilled, (state) => {
        state.fetchVoteImagesState = EReducerState.IDLE;
        if (state.voteDisplayState !== EVoteDisplayState.ACTIVE) {
          state.voteDisplayState = EVoteDisplayState.ACTIVE;
        }
        console.log(
          'fetchVoteImagesAsync.fulfilled: state.fetchVoteImageState: ',
          state.fetchVoteImagesState,
        );
      })
      .addCase(fetchVoteImagesAsync.rejected, (state, action) => {
        state.fetchVoteImagesState = EReducerState.FAILED;
        console.log(
          'fetchVoteImagesAsync.rejected: state.fetchVoteImageState: ',
          state.fetchVoteImagesState,
        );
        console.log('fetchVoteImagesAsync.rejected: action: ', action.error);
      })
      .addCase(voteOnImageAsync.pending, (state) => {
        state.voteOnImagesState = EReducerState.LOADING;
        console.log(
          'voteOnImageAsync.pending: state.voteOnImagesState: ',
          state.voteOnImagesState,
        );
      })
      .addCase(voteOnImageAsync.fulfilled, (state) => {
        state.voteOnImagesState = EReducerState.IDLE;
        console.log(
          'voteOnImageAsync.fulfilled: state.voteOnImagesState: ',
          state.voteOnImagesState,
        );
      })
      .addCase(voteOnImageAsync.rejected, (state, action) => {
        state.voteOnImagesState = EReducerState.FAILED;
        console.log(
          'voteOnImageAsync.rejected: state.voteOnImagesState: ',
          state.voteOnImagesState,
        );
        console.log('voteOnImageAsync.rejected: action: ', action.error);
      });
  },
});

export const fetchVoteImagesAsync = createAsyncThunk(
  'vote/fetchVoteImagesAsync',
  async (
    getVoteContentDto: GetVoteContentDto,
    { dispatch, getState, rejectWithValue },
  ): Promise<DisplayVoteContent[]> => {
    try {
      //get content from api
      const state: RootState = getState() as RootState;
      const token: string = selectSiweToken(state);
      if (!token) {
        throw new Error('fetchVoteImagesAsync: siweState.token is undefined');
      }

      const alreadyFetched: string[] = selectImages(state).map(
        (content: { content: { id: string } }) => content.content.id,
      );

      //apppend uncofirmed vote ids to already fetched
      alreadyFetched.push(...state.vote.unconfirmedVoteIDs);
      alreadyFetched.push(...state.vote.errorVotedIDs);

      getVoteContentDto.contentCount = 10;
      getVoteContentDto.alreadyFetchedIds = alreadyFetched;

      const response = await apiAxios.post(
        '/content/get-content-for-voting',
        getVoteContentDto,
      );
      const fetchedContent: Content[] = response.data as Content[];

      //get images from content
      const displayVoteContent: DisplayVoteContent[] = [];
      for (const content of fetchedContent) {
        if (!content.contentUrl) {
          console.error(
            `fetchVoteImagesAsync: content.contentUrl is undefined for content: ${content.id}`,
          );
        }
        let imageResponse: any;
        try {
          imageResponse = await axios(content.contentUrl!, {
            responseType: 'blob',
          });
        } catch (err) {
          //catch 403 error
          const error: Error = err as Error;
          if (error.message.includes('403')) {
            console.error('fetchVoteImagesAsync: caught 403 error');
            //TODO -- report to backend error content found
            dispatch(markIdAsError(content.id));
            continue;
          }
        }
        const blob = new Blob([imageResponse.data], { type: 'image/png' });
        const dataUrl: string = URL.createObjectURL(blob);
        const displayContent: DisplayVoteContent = {
          content: content,
          imageData: dataUrl,
        };
        dispatch(addImage(displayContent));
        displayVoteContent.push(displayContent);
      }
      if (displayVoteContent.length === 0) {
        console.log('TODO -- handle no more content!');
        Emitter.emit('END_OF_VOTE_CONTENT', null);
        //dispatch(setVoteDisplayState(EVoteDisplayState.END));
        throw rejectWithValue('No more content to vote on');
      }
      return displayVoteContent;
    } catch (err: unknown) {
      const error: Error = err as Error;
      throw rejectWithValue(error.message);
    }
  },
  {
    condition: (_, { getState, extra }) => {
      const { vote: state } = getState() as { vote: VoteState };
      if (state.fetchVoteImagesState === EReducerState.LOADING) {
        console.log('fetchVoteImagesAsync: already loading');
        return false;
      }
    },
  },
);

export const voteOnImageAsync = createAsyncThunk(
  'vote/voteOnImageAsync',
  async (
    voteDto: ContentVoteDto,
    { dispatch, getState, rejectWithValue },
  ): Promise<void> => {
    try {
      const { siwe: siweState } = getState() as { siwe: SiweState };
      if (!siweState) {
        throw rejectWithValue('voteOnImageAsync failed to get SiweState');
      }
      const token: string = siweState.token;
      if (!token) {
        throw rejectWithValue('voteOnImageAsync failed to get siwe token');
      }
      if (!voteDto.contentId || !voteDto.vote) {
        throw rejectWithValue('voteOnImageAsync: voteDto is invalid');
      }
      await apiAxios.post('/content/vote-on-content', voteDto);
      //safe to clear after voting
      dispatch(clearImages());
    } catch (err: unknown) {
      const message = (err as Error).message;
      throw rejectWithValue(message);
    }
  },
);

export const fetchMoreImagesAsync = createAsyncThunk(
  'vote/fetchMoreimagesAsync',
  async (getVoteContentDto: GetVoteContentDto, { dispatch }): Promise<void> => {
    //fetch more images
    dispatch(fetchVoteImagesAsync(getVoteContentDto));
  },
  {
    condition: (_, { getState, extra }) => {
      const { vote: state } = getState() as { vote: VoteState };
      if (state.fetchVoteImagesState === EReducerState.LOADING) {
        console.log('fetchMoreImagesAsync: already loading');
        return false;
      }
    },
  },
);

export const {
  storeImages,
  nextImage,
  clearImages,
  addImage,
  markIdAsError,
  resetVoteState,
  setVoteDisplayState,
  setVoteRequestState,
  setEndOfCuratedContent,
} = voteSlice.actions;
export const voteReducer = voteSlice.reducer;
