import { ContentRatingDto } from './types';
import { EContentRating } from './enum';
import { updateContentRatingAsync } from './moderation-slice';
import { store } from '../../app/store';

export function moderateContentRating(
  contentId: string,
  rating: EContentRating,
) {
  try {
    const ratingDto: ContentRatingDto = {
      contentId: contentId,
      contentRating: rating,
    };
    store.dispatch(updateContentRatingAsync(ratingDto));
  } catch (error) {
    console.error('Error moderating content rating', error);
  }
}
