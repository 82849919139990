import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, store } from '../../../app/store';
import {
  SiweNonceCredential,
  SiweState,
  isSiweNonceCredential,
  SiweVerifyCredential,
  SiweCredentialPayload,
  PerformSiweDto,
} from './types';
import { ApiJwt, isApiJwt } from '../../../app/types';
import {
  createSiweMessage,
  siweSignMessage,
  siweVerifyMessage,
  verifySiweSignedIn,
} from './siwe';

const API_URI: string = process.env.REACT_APP_API_URI as string;

const initialState: SiweState = {
  token: '',
  status: 'idle',
  isLoggedIn: false,
  nonceCredentials: null,
  verifyCredentials: null,
};

export const selectSiweIsLoggedIn = (state: RootState) => state.siwe.isLoggedIn;
export const selectSiweToken = (state: RootState) => state.siwe.token;
export const selectSiweRole = (state: RootState) => state.siwe.role;

export const siweSlice = createSlice({
  name: 'siwe',
  initialState,
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
      if (!action.payload) { // logout
        state.token = '';
        state.nonceCredentials = null;
        state.verifyCredentials = null;
        state.role = undefined;
      }
      console.log(
        'siweSlice: setIsLoggedIn: state.isLoggedIn: ',
        state.isLoggedIn,
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(performSiweAsync.pending, (state) => {
        state.status = 'loading';
        console.log('perform siwe async pending');
      })
      .addCase(
        performSiweAsync.fulfilled,
        (state, action: PayloadAction<SiweCredentialPayload>) => {
          const payload: SiweCredentialPayload = action.payload;
          const nonceCredentials: SiweNonceCredential =
            payload.nonceCredentials;
          const verifyCredentials: SiweVerifyCredential =
            payload.verifyCredentials;
          const token: string = payload.token;

          state.nonceCredentials = nonceCredentials;
          state.verifyCredentials = verifyCredentials;
          state.token = token;
          state.isLoggedIn = true;
          state.status = 'idle';
          state.role = payload.role;
          console.log('perform siwe async fulfilled');
        },
      )
      .addCase(performSiweAsync.rejected, (state, action) => {
        state.status = 'failed';
        console.log(`perform siwe async rejected ${action.error.message}`);
        console.log(action.error);
      });
  },
});

//get nonce from backend
export const performSiweAsync = createAsyncThunk(
  'siwe/perform-siwe',
  async (performSiweDto: PerformSiweDto, { getState }) => {
    const credentials: SiweNonceCredential = performSiweDto.siweNonceCredential;
    const state: RootState = getState() as RootState;
    const siweState: SiweState = state.siwe;
    if (!performSiweDto.isForce) {
      const signedIn = await verifySiweSignedIn(siweState);
      if (signedIn) {
        console.log('already signed in!');
        const payload: SiweCredentialPayload = {
          nonceCredentials: siweState.nonceCredentials!,
          verifyCredentials: siweState.verifyCredentials!,
          token: siweState.token,
          role: siweState.role,
        };
        store.dispatch(setIsLoggedIn(true));
        return payload;
      } else {
        store.dispatch(setIsLoggedIn(false));
      }
    }
    const address = credentials.address;
    const nonceResponse = await fetch(`${API_URI}/auth/login/siwe/get-nonce`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({ address: address }),
    });

    const noncePayload: SiweNonceCredential =
      (await nonceResponse.json()) as SiweNonceCredential;
    console.log(noncePayload);
    if (!isSiweNonceCredential(noncePayload)) {
      throw new Error(`Invalid credential on async 
                get nonce fulfilled: ${JSON.stringify(noncePayload)}`);
    }

    // form siwe message
    const message: string = createSiweMessage(
      noncePayload.address,
      noncePayload.nonce!,
    );

    const signature: string = await siweSignMessage(message);

    const verifyMessage: SiweVerifyCredential = {
      message: message,
      signature: signature,
    };

    await siweVerifyMessage(message, signature);

    const verifyResponse = await fetch(`${API_URI}/auth/login/siwe/verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(verifyMessage),
    });

    const verifyPayload: ApiJwt = (await verifyResponse.json()) as ApiJwt;

    if (!isApiJwt(verifyPayload)) {
      throw new Error(`Could not verify siwe payload`);
    }

    const credentialPayload: SiweCredentialPayload = {
      nonceCredentials: noncePayload,
      verifyCredentials: verifyMessage,
      token: verifyPayload.token,
      role: verifyPayload.role,
    };

    return credentialPayload;
  },
  {
    condition: (performSiweDto: PerformSiweDto, { getState, extra }) => {
      const { siwe: state } = getState() as { siwe: SiweState };
      if (state.status === 'loading') {
        console.log('perform siwe async already loading');
        return false;
      }
    },
  },
);

export const { setIsLoggedIn } = siweSlice.actions;
export const siweReducer = siweSlice.reducer;
