import { CreepKidCheckerAction } from '../../web3/eth/creep-kid-checker';
import { performSiweAsync } from './siwe-auth-slice';
import { SiweNonceCredential } from './types';
import { store } from '../../../app/store';

export async function siweConnectLogin(
  address: `0x${string}`,
  isForce?: boolean,
) {
  try {
    const ckOwner: boolean = await CreepKidCheckerAction(address);
    if (ckOwner) {
      console.log('Siwe Auth: Owns a Creep Kid!');
    } else {
      console.log('Siwe Auth: Does not own a Creep Kid!');
      return;
    }

    const siweCredential: SiweNonceCredential = {
      address: address,
    };
    const result = await store.dispatch(
      performSiweAsync({
        siweNonceCredential: siweCredential,
        isForce: isForce,
      }) as any,
    );
    console.log(`siweConnectLogin: `, result);
  } catch (err) {
    console.log(err);
  }
}
