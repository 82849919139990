import { SiweMessage } from 'siwe';
import { signMessage, getAccount } from '@wagmi/core';
import { SiweState } from './types';

export function createSiweMessage(address: string, nonce: string): string {
  const message = createSiweMessageObject(address, nonce);
  return message.prepareMessage();
}

export function createSiweMessageObject(
  address: string,
  nonce: string,
): SiweMessage {
  const domain = window.location.host;
  const origin = window.location.origin;
  const message = new SiweMessage({
    domain: domain,
    address: address,
    statement: 'I agree to use my ethereum address to sign in to nilor.cool',
    uri: origin,
    version: '1',
    chainId: 1,
    nonce: nonce,
  });
  return message;
}

export async function verifySiweSignedIn(state: SiweState): Promise<boolean> {
  if (!state.nonceCredentials) {
    return false;
  }
  if (!state.verifyCredentials) {
    return false;
  }
  if (!state.token) {
    return false;
  }

  const { address, isConnected } = getAccount();
  if (!isConnected) {
    return false;
  }
  if (!address) {
    return false;
  }
  if (!state.nonceCredentials.nonce) {
    return false;
  }
  const message: SiweMessage = new SiweMessage(state.verifyCredentials.message);
  if (address !== message.address) {
    return false;
  }

  try {
    siweVerifyMessage(
      state.verifyCredentials.message,
      state.verifyCredentials.signature,
    );
  } catch (e) {
    return false;
  }
  return true;
}

export async function siweVerifyMessage(message: string, signature: string) {
  const messageObject = new SiweMessage(message);
  const { error } = await messageObject.verify({
    signature: signature,
    nonce: messageObject.nonce,
  });
  if (error) {
    throw new Error(`Could not verify siwe message: ${error}`);
  }
}

export function siweSignMessage(message: string): Promise<string> {
  return signMessage({ message: message });
}
