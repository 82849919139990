import { Content } from '../../app/types';
import { EContentVote, EVoteRequestType } from './enum';
import { ContentVoteDto, DisplayVoteContent, GetVoteContentDto } from './types';
import { store } from '../../app/store';
import { VoteState } from './vote-state';
import {
  nextImage,
  voteOnImageAsync,
  fetchMoreImagesAsync,
} from './vote-slice';

//TODO: move to config or paramaterize
const contentFetchAmount = 10;

export function getContentVoteDto(
  content: Content,
  voteType: EContentVote,
): ContentVoteDto {
  if (!content.id || !voteType) {
    throw new Error('getContentVoteDto: content.id or voteType is null');
  }
  return {
    contentId: content.id,
    vote: voteType,
  };
}

export function goToNextImage(): void {
  try {
    store.dispatch(nextImage());
  } catch (err) {
    console.error(`GoToNextImage failed: ${err}`);
  }
}

export function shouldFetchMoreImages(): boolean {
  try {
    const { vote: voteState } = store.getState() as { vote: VoteState };
    return (
      voteState.images.length - 1 <= contentFetchAmount / 2 ||
      voteState.index >= (voteState.images.length - 1) / 2
    );
  } catch (err) {
    console.error(`ShouldFetchMoreImages failed: ${err}`);
    throw err;
  }
}

export function voteOnContent(
  displayContent: DisplayVoteContent,
  voteType: EContentVote,
  voteRequestType: EVoteRequestType,
): void {
  try {
    const voteDto: ContentVoteDto = getContentVoteDto(
      displayContent?.content,
      voteType,
    );
    const { vote: voteState } = store.getState() as { vote: VoteState };
    store.dispatch(nextImage());
    store.dispatch(voteOnImageAsync(voteDto)).then(() => {
      if (
        voteState.images.length - 1 <= contentFetchAmount / 2 ||
        voteState.index >= (voteState.images.length - 1) / 2
      ) {
        const getVoteContentDto: GetVoteContentDto = {
          contentCount: contentFetchAmount,
          alreadyFetchedIds: [],
          voteRequestType: voteRequestType,
        };
        store.dispatch(fetchMoreImagesAsync(getVoteContentDto));
      }
    });
  } catch (err) {
    console.log(`VoteOnContent failed: ${err}`);
  }
}
