import { useParams } from 'react-router-dom';
import { ConsultationForm } from '../components';
import { Header } from '../features/header';
import { useAppSelector } from '../app/hooks';
import { selectSiweIsLoggedIn } from '../features/auth/siwe-auth/siwe-auth-slice';
import { selectGauthIsLoggedIn } from '../features/auth/gauth/gauth-slice';

export const Consult = () => {
  const { collectionId } = useParams<{ collectionId: string }>();
  const isSiweLoggedIn = useAppSelector(selectSiweIsLoggedIn);
  const isGauthLoggedIn = useAppSelector(selectGauthIsLoggedIn);
  const isLoggedIn = isSiweLoggedIn || isGauthLoggedIn;

  return (
    <>
      <Header />
      {isLoggedIn && (
        <div className="p-6 lg:p-8">
          <ConsultationForm collectionId={collectionId || ''} />
        </div>
      )}
    </>
  );
};
