import { useState } from 'react';
import {
  BoltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  FireIcon,
  PencilIcon,
} from '@heroicons/react/20/solid';
import { ImagePosition } from '../features/generate/generate-images';
interface InstrumentTopDrawerProps {
  canClose: boolean;
  selectedImages: ImagePosition[];
  hide?: boolean;
}

export const InstrumentTopDrawer = ({
  canClose,
  selectedImages,
  hide,
}: InstrumentTopDrawerProps) => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [userInput, setUserInput] = useState('');

  const handleUserInputChange = (event: any) => {
    setUserInput(event.target.value);
  };

  if (hide) {
    return null;
  }

  return (
    <>
      {canClose && (
        <div className="flex w-[34.5rem] justify-center">
          <div
            className={`flex w-[3rem] bg-instrument-topdrawer-back justify-center items-center rounded-t-lg text-black cursor-pointer`}
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            {drawerOpen ? (
              <ChevronUpIcon className="w-4 h-4" />
            ) : (
              <ChevronDownIcon className="w-4 h-4" />
            )}
          </div>
        </div>
      )}
      {drawerOpen && (
        <div className="w-[34.5rem] p-3 bg-instrument-topdrawer rounded-t-2xl">
          <div className="flex justify-between items-center text-white">
            <input
              type="search"
              name="search"
              id="search"
              className="flex bg-white rounded-2xl p-2 h-[2.75rem] w-[22rem] text-gray-900 border-transparent"
              placeholder="Type in something to explore..."
              value={userInput}
              onChange={handleUserInputChange}
            />
            <div
              className="instrument-button-medium bg-instrument-topdrawer-summon"
              title="Summon"
              onClick={() => {}}
            >
              <FireIcon />
            </div>
            <div
              className="instrument-button-medium bg-instrument-topdrawer-inject"
              title="Inject"
              onClick={() => {}}
            >
              <PencilIcon />
            </div>
            <div
              className="instrument-button-medium bg-instrument-topdrawer-roll"
              title="Roll"
              onClick={() => {}}
            >
              <BoltIcon />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
