import { useEffect, useRef, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  selectIndex,
  selectImages,
  fetchVoteImagesAsync,
  selectVoteDisplayState,
  resetVoteState,
  setVoteDisplayState,
  selectVoteRequestState,
  setVoteRequestState,
  selectEndOfCuratedContent,
  setEndOfCuratedContent,
} from './vote-slice';
import { DisplayVoteContent } from './types/display-vote-content';
import { voteOnContent, goToNextImage, shouldFetchMoreImages } from './vote';
import { moderateContentRating } from '../moderation/moderate';
import { EContentRating } from '../moderation/enum';
import { EContentVote, EVoteDisplayState, EVoteRequestType } from './enum';
import { GetVoteContentDto } from './types';
import { useAccount } from 'wagmi';
import { selectIsCkHolder } from '../web3/eth/eth-slice';
import {
  selectSiweIsLoggedIn,
  selectSiweRole,
} from '../auth/siwe-auth/siwe-auth-slice';
import { ERole } from '../auth/enum';
import { PingingDemon } from './pinging-demon';
import { EndOfContent } from './end-of-content';
import Emitter from '../../app/emitter';
import { EEvents } from '../../app/enum';
import { store } from '../../app/store';
import { Switch } from '@headlessui/react';
import { CakeIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

export function VoteImageDispay() {
  const { isConnected } = useAccount();
  const isCkHolder = useAppSelector(selectIsCkHolder);
  const isSiweLoggedIn = useAppSelector(selectSiweIsLoggedIn);
  return (
    <div>
      {isConnected && isCkHolder && isSiweLoggedIn && <VoteComponent />}
    </div>
  );
}

function onAddressChange() {
  console.log('VOTE_IMAGE address changed');
  store.dispatch(resetVoteState());
}

export function VoteComponent() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const index = useAppSelector(selectIndex);
  const images: DisplayVoteContent[] = useAppSelector(selectImages);
  const displayState: EVoteDisplayState = useAppSelector(
    selectVoteDisplayState,
  );
  const explorerRole: ERole = useAppSelector(selectSiweRole);
  const sliceVoteRequestState: EVoteRequestType = useAppSelector(
    selectVoteRequestState,
  );
  const sliceEndOfCurated = useAppSelector(selectEndOfCuratedContent);
  const voteRequestState = useRef(sliceVoteRequestState);
  const [curatedToggle, setCuratedToggle] = useState(
    sliceVoteRequestState === EVoteRequestType.CURATED ? true : false,
  );
  const endOfCurated = useRef(sliceEndOfCurated);
  console.log('GLOBAL REQUEST STATE', sliceVoteRequestState);

  //EMITTER
  useEffect(() => {
    Emitter.on(EEvents.ADDRESS_CHANGED, onAddressChange);
    Emitter.on(EEvents.END_OF_VOTE_CONTENT, onEndOfVoteContent);

    function onEndOfVoteContent() {
      if (!shouldFetchMoreImages()) {
        setCuratedToggle(
          voteRequestState.current === EVoteRequestType.CURATED ? true : false,
        );
        return;
      }
      if (curatedToggle === true) {
        voteRequestState.current = EVoteRequestType.ALL_CONTENT;
        endOfCurated.current = true;
        dispatch(setVoteRequestState(EVoteRequestType.ALL_CONTENT));
        dispatch(setEndOfCuratedContent(true));
        setCuratedToggle(false);
      } else {
        if (voteRequestState.current === EVoteRequestType.ALL_CONTENT) {
          store.dispatch(setVoteDisplayState(EVoteDisplayState.END));
          voteRequestState.current = EVoteRequestType.CURATED;
          dispatch(setVoteRequestState(EVoteRequestType.CURATED));
        }
      }
    }
    return () => {
      Emitter.off(EEvents.ADDRESS_CHANGED, onAddressChange);
      Emitter.off(EEvents.END_OF_VOTE_CONTENT, onEndOfVoteContent);
    };
  }, [curatedToggle, setCuratedToggle, endOfCurated, dispatch]);

  //
  useEffect(() => {
    let isReset: boolean;
    if (curatedToggle === true) {
      if (voteRequestState.current === EVoteRequestType.CURATED) {
        isReset = false;
      } else {
        isReset = true;
        voteRequestState.current = EVoteRequestType.CURATED;
        dispatch(setVoteRequestState(EVoteRequestType.CURATED));
      }
    } else {
      if (voteRequestState.current === EVoteRequestType.ALL_CONTENT) {
        isReset = false;
      } else {
        isReset = true;
        voteRequestState.current = EVoteRequestType.ALL_CONTENT;
        dispatch(setVoteRequestState(EVoteRequestType.ALL_CONTENT));
      }
    }

    const getVoteContentDto: GetVoteContentDto = {
      contentCount: 5,
      alreadyFetchedIds: [],
      voteRequestType: voteRequestState.current,
    };

    if (isReset) {
      dispatch(resetVoteState());
      dispatch(fetchVoteImagesAsync(getVoteContentDto));
    } else {
      dispatch(fetchVoteImagesAsync(getVoteContentDto));
    }
  }, [curatedToggle, dispatch]);

  return (
    <div className="pt-16 h-[100vh] overflow-auto w-[100vw]">
      {displayState === EVoteDisplayState.END && <EndOfContent />}
      {displayState === EVoteDisplayState.EMPTY && <PingingDemon />}
      {displayState === EVoteDisplayState.ACTIVE && (
        <main className="mx-auto px-4 pb-24 pt-14 sm:px-6 sm:pb-32 sm:pt-16 lg:max-w-7xl lg:px-8">
          <div className="pb-5">
            <button
              type="button"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 p-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => navigate('/Leaderboard')}
            >
              <CakeIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              View Leaderboard
            </button>
          </div>
          <div className="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-5">
            {/* image */}
            <div className="lg:col-span-4 lg:row-end-1">
              <div className="aspect-h-4 aspect-w-4 overflow-hidden rounded-lg bg-gray-100">
                <img
                  src={images[index]?.imageData}
                  alt={images[index].content.contentType}
                  width={512}
                  height={512}
                  className="object-cover object-center"
                />
              </div>
            </div>

            {/* image text details */}
            <div className="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
              {/* <div className="flex flex-col-reverse">
                <div className="mt-4">
                  TODO: fetch nft data for name
                  <h1 className="text-2xl font-bold tracking-tight text-white sm:text-3xl">
                    {product.name}
                  </h1>

                  <h2 id="information-heading" className="sr-only"></h2>
                </div>
              </div> */}
              <Switch.Group
                as="div"
                className={classNames(
                  !endOfCurated.current
                    ? 'bg-gray-800'
                    : 'bg-rose-500 opacity-60 ',
                  'flex items-center bg-gray-800 rounded-lg sm:p-6',
                )}
              >
                <Switch
                  checked={curatedToggle}
                  onChange={endOfCurated.current ? () => {} : setCuratedToggle}
                  className={classNames(
                    curatedToggle ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      curatedToggle ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3 text-sm">
                  {curatedToggle && (
                    <span className="text-gray-100 ">Curated Content</span>
                  )}
                  {!curatedToggle && !endOfCurated.current && (
                    <span className="text-gray-50">Curated Content</span>
                  )}
                  {!curatedToggle && endOfCurated.current && (
                    <div>
                      <span className="text-slate-400">Curated Content </span>
                      <span className="text-white">
                        {' '}
                        (Toggle is now Disabled. All curated content has been
                        voted on)
                      </span>
                    </div>
                  )}
                </Switch.Label>
              </Switch.Group>
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-3">
                <button
                  type="button"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                  onClick={() => {
                    voteOnContent(
                      images[index],
                      EContentVote.NOT_COOL,
                      voteRequestState.current,
                    );
                  }}
                >
                  not cool
                </button>
                <button
                  type="button"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-50 px-8 py-3 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                  onClick={() => {
                    voteOnContent(
                      images[index],
                      EContentVote.COOL,
                      voteRequestState.current,
                    );
                  }}
                >
                  cool
                </button>
                <button
                  type="button"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-pink-400 px-8 py-3 text-base font-medium text-indigo-950 hover:bg-pink-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                  onClick={() => {
                    voteOnContent(
                      images[index],
                      EContentVote.ULTRA_COOL,
                      voteRequestState.current,
                    );
                  }}
                >
                  🔥 ULTRA COOL 🔥
                </button>
                {explorerRole === ERole.ULTRA && (
                  <button
                    type="button"
                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-red-600 px-8 py-3 text-base font-medium text-white hover:bg-red-700 focus:outline-none"
                    onClick={() => {
                      moderateContentRating(
                        images[index].content.id,
                        EContentRating.NSFW,
                      );
                      goToNextImage();
                    }}
                  >
                    flag nsfw
                  </button>
                )}
              </div>

              <p className="mt-6 w-96 text-gray-300">
                {images[index]?.content.prompt}
              </p>
            </div>
          </div>
        </main>
      )}
    </div>
  );
}

export function VoteImage() {
  const dispatch = useAppDispatch();
  const index = useAppSelector(selectIndex);
  const images: DisplayVoteContent[] = useAppSelector(selectImages);
  const displayState: EVoteDisplayState = useAppSelector(
    selectVoteDisplayState,
  );

  useEffect(() => {
    const getVoteContentDto: GetVoteContentDto = {
      contentCount: 5,
      alreadyFetchedIds: [],
      voteRequestType: EVoteRequestType.ALL_CONTENT,
    };
    dispatch(fetchVoteImagesAsync(getVoteContentDto));
  }, [dispatch]);

  return (
    <div>
      {displayState === EVoteDisplayState.EMPTY && (
        <p>Loading Image Voting...</p>
      )}
      {displayState === EVoteDisplayState.ACTIVE && (
        <div>
          <img src={images[index]?.imageData} alt="" />
          <button
            className="bg-red-400 hover:bg-red-300 text-white font-bold py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded"
            onClick={() => {
              voteOnContent(
                images[index],
                EContentVote.NOT_COOL,
                EVoteRequestType.ALL_CONTENT,
              );
            }}
          >
            no
          </button>
          <button
            className="bg-green-400 hover:bg-green-400 text-white font-bold py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded"
            onClick={() => {
              voteOnContent(
                images[index],
                EContentVote.COOL,
                EVoteRequestType.ALL_CONTENT,
              );
            }}
          >
            yes
          </button>
        </div>
      )}
    </div>
  );
}
