import {
  ArrowPathRoundedSquareIcon,
  ArrowRightOnRectangleIcon,
  ArrowUpTrayIcon,
  ArrowsPointingInIcon,
  BeakerIcon,
  CubeIcon,
  CubeTransparentIcon,
  EyeDropperIcon,
  FolderOpenIcon,
  FolderPlusIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import { sortBy } from 'lodash';
import React from 'react';

interface Term {
  name: string;
  description: string;
  icon?: React.ReactNode;
}

const iconClass = 'h-full w-full';

const dictionary: Term[] = [
  {
    name: 'Direction',
    description:
      'Buttons you can create yourself to guide an image in any way you want. You can edit these in the instrument tab of settings.',
    icon: null,
  },
  {
    name: 'Upcycle',
    description:
      'Creates alternate descriptions that are synonymous to the current prompt and re-generates the image.',
    icon: <ArrowPathRoundedSquareIcon className={iconClass} />,
  },
  {
    name: 'Mimic',
    description: 'Make "copies" of the current image with a slight twist.',
    icon: <EyeDropperIcon className={iconClass} />,
  },
  {
    name: 'Smoosh',
    description: 'Blend two images together.',
    icon: <ArrowsPointingInIcon className={iconClass} />,
  },
  {
    name: 'Feed',
    description:
      'Upload an image. Add keywords if you would like to explicitly describe the image you are uploading. The image will be processed to catch the "essence" of what you feed, and also an unmodified reference image will be uploaded.',
    icon: <ArrowUpTrayIcon className={iconClass} />,
  },
  {
    name: 'Square/Tall/Wide',
    description:
      'Choose whether to generate square, tall, or wide images. Tall images are better for vertical screens like phones.',
    icon: null,
  },
  {
    name: 'Combine',
    description:
      'Takes all of your Direction buttons and turns it into one giant mega button.',
    icon: <BeakerIcon className={iconClass} />,
  },
  {
    name: 'Select/Deselect All',
    description:
      'Select or deselect all images in the grid - this is usable via the selection counter button on the instrument.',
    icon: null,
  },
  {
    name: 'Summon',
    description: 'Generate an image from the prompt you typed in.',
    icon: null,
  },
  {
    name: 'Instrument',
    description:
      'The main control panel for the images - this lives in the center bottom of the screen.',
    icon: null,
  },
  {
    name: 'Load Collection',
    description:
      'A menu that lets you change your current collection or delete collections.',
    icon: <FolderOpenIcon className={iconClass} />,
  },
  {
    name: 'Package',
    description: 'Save selected images to your device as a ZIP.',
    icon: <CubeIcon className={iconClass} />,
  },
  {
    name: 'Disappear',
    description: 'Permanently remove the image from the grid.',
    icon: <TrashIcon className={iconClass} />,
  },
  {
    name: 'Fork',
    description: 'Move the image over into a brand new column.',
    icon: <ArrowRightOnRectangleIcon className={iconClass} />,
  },
  {
    name: 'Move',
    description: 'Move the image up, down, left, or right.',
    icon: <CubeTransparentIcon className={iconClass} />,
  },
  {
    name: 'Add to Collection',
    description: 'Add the image to a different collection.',
    icon: <FolderPlusIcon className={iconClass} />,
  },
  {
    name: 'Create Collection',
    description:
      'Create a new collection with this image. This is available via the create card in the collections modal.',
    icon: <FolderPlusIcon className={iconClass} />,
  },
  {
    name: 'Loadout',
    description:
      'A saved set of directions that you can apply to an image. You can edit these in the instrument tab of settings.',
    icon: null,
  },
];

export const Glossary = () => {
  return (
    <div className="w-full h-full overflow-y-auto">
      {sortBy(dictionary, ['name']).map((term, index) => (
        <div
          className={`flex flex-col p-2 ${
            index % 2 === 0 ? 'bg-gray-100' : 'bg-gray-200'
          }`}
          key={`glossary-${term.name}`}
        >
          <div className="flex justify-between">
            <div className="font-bold">{term.name}</div>
            <div className="h-8 w-8 text-black">{term.icon}</div>
          </div>
          <div className="flex w-full text-left">{term.description}</div>
        </div>
      ))}
    </div>
  );
};
