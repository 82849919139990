import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DisplayVoteContent, VoteLeaderContentDto } from './types';
import { VoteLeadersState } from './vote-leaders-state';
import { EReducerState } from '../../app/enum';
import { apiAxios } from '../../app/axios';
import { Content } from '../../app/types';
import axios from 'axios';
import { RootState } from '../../app/store';

const initialState: VoteLeadersState = {
  images: [],
  errorImageIds: [],
  status: EReducerState.IDLE,
};

export const selectLeaderImages = (state: RootState) => state.voteLeader.images;
export const selectLeaderStatus = (state: RootState) => state.voteLeader.status;

export const voteLeadersSlice = createSlice({
  name: 'voteLeaders',
  initialState,
  reducers: {
    addImage: (state, action: PayloadAction<DisplayVoteContent>) => {
      state.images.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeaderImagesAsync.pending, (state) => {
        state.status = EReducerState.LOADING;
        console.log('fetchLeaderImages.pending: state: ', state.status);
      })
      .addCase(fetchLeaderImagesAsync.fulfilled, (state, action) => {
        state.status = EReducerState.IDLE;
        console.log('fetchLeaderImages.fulfilled: state: ', state.status);
      })
      .addCase(fetchLeaderImagesAsync.rejected, (state, action) => {
        state.status = EReducerState.FAILED;
        console.log('fetchLeaderImages.rejected: error: ', action.error);
      });
  },
});

export const fetchLeaderImagesAsync = createAsyncThunk(
  'voteLeaders/fetchLeaderImages',
  async (
    _,
    { dispatch, rejectWithValue, getState },
  ): Promise<DisplayVoteContent[]> => {
    const { voteLeader: leaderState } = getState() as {
      voteLeader: VoteLeadersState;
    };
    const alreadyFetched: string[] = leaderState.images.map(
      (content: { content: { id: string } }) => content.content.id,
    );
    const voteLeaderDto: VoteLeaderContentDto = {
      contentCount: 12,
      alreadyFetchedIds: alreadyFetched,
    };
    let response;
    try {
      response = await apiAxios.post(
        '/content/get-content-for-leaderboard',
        voteLeaderDto,
      );
    } catch (err) {
      throw rejectWithValue(err);
    }

    const fetchedContent: Content[] = response.data;
    console.log('fetchLeaderImagesAsync: fetchedContent: ', fetchedContent);
    //throw not implemnted
    const displayContent: DisplayVoteContent[] = [];
    for (const content of fetchedContent) {
      if (!content.contentUrl) {
        console.error('fetchLeaderImagesAsync: content.contentUrl is null');
      }
      let imageResponse: any;
      try {
        console.log('fetch');
        imageResponse = await axios.get(content.contentUrl!, {
          responseType: 'blob',
        });
      } catch (err) {
        const error: Error = err as Error;
        console.error('fetchLeaderImagesAsync: error: ', error);
        if (error.message.includes('403')) {
          console.error('fetchVoteImagesAsync: caught 403 error');
          console.error('TODO -- add to error list');
          //TODO -- report to backend error content found
          continue;
        }
      }
      const blob = new Blob([imageResponse.data], { type: 'image/png' });
      const dataUrl: string = URL.createObjectURL(blob);
      const displayVoteContent: DisplayVoteContent = {
        content: content,
        imageData: dataUrl,
      };
      dispatch(addImage(displayVoteContent));
      displayContent.push(displayVoteContent);
    }
    if (displayContent.length === 0) {
      //TODO handle end of cotntent
      console.error('fetchLeaderImagesAsync: displayContent.length === 0');
    }
    return displayContent;
  },
  {
    condition: (_, { getState }) => {
      const { voteLeader: state } = getState() as {
        voteLeader: VoteLeadersState;
      };
      if (state.status === EReducerState.LOADING) {
        console.log('fetchLeaderImagesAsync: already loading');
        return false;
      }
    },
  },
);

export const { addImage } = voteLeadersSlice.actions;
export const voteLeaderReducer = voteLeadersSlice.reducer;
