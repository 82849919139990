import { useAppDispatch } from '../../../app/hooks';
import { useEffect } from 'react';
import { useAccount, ConnectorData } from 'wagmi';
import { WalletConnectButton } from '../wallet-connect/wallet-connect';
import { siweConnectLogin } from './siwe-connect-login';
import { setIsLoggedIn } from './siwe-auth-slice';
import Emitter from '../../../app/emitter';
import { EEvents } from '../../../app/enum';

export function SiweAuth() {
  const dispatch = useAppDispatch();
  const {
    // address,
    // isConnected,
    connector: activeConnector,
  } = useAccount({
    async onConnect({ address, connector, isReconnected }) {
      console.log('Connected', { address, connector, isReconnected });
      if (!address) {
        console.error('On connect failed to get address');
        return;
      }
      await siweConnectLogin(address);
    },
    async onDisconnect() {
      dispatch(setIsLoggedIn(false));
      Emitter.emit(EEvents.ADDRESS_CHANGED, null);
      console.log('Disconnected');
    },
  });

  useEffect(() => {
    const handleConnectorUpdate = async ({ account, chain }: ConnectorData) => {
      if (account) {
        console.log('new account', account);
        dispatch(setIsLoggedIn(false));
        await siweConnectLogin(account);
        Emitter.emit(EEvents.ADDRESS_CHANGED, account);
      } else if (chain) {
        console.log('new chain', chain);
      }
    };

    if (activeConnector) {
      activeConnector.on('change', handleConnectorUpdate);
    }
    return () => {
      if (activeConnector) {
        activeConnector!.off('change', handleConnectorUpdate);
      }
    };
  }, [activeConnector, dispatch]);

  return (
    <div>
      <WalletConnectButton />
      {/* {isConnected && `Address: ${address}`}
      {isConnected && address && <CreepKidCheckerHook address={address} />} */}
    </div>
  );
}
