import { get } from 'lodash';
import { GenerateImageModalProps } from '../../components/ViewImageModal';
import { DisplayImageContent } from './types';
import {
  ArrowUpTrayIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/20/solid';
import { DLinkList } from 'd-link-list';

interface ImageFrameProps {
  image: DisplayImageContent;
  listIndex: number;
  imageIndex: number;
  imageList: DLinkList<DisplayImageContent>;
  modalProps: GenerateImageModalProps;
  height: number;
  width: number;
}

export const ImageFrame = ({
  image,
  listIndex,
  imageIndex,
  imageList,
  modalProps,
  height,
  width,
}: ImageFrameProps) => {
  const isReference = get(image, 'content.isReference');

  return (
    <div className={`overflow-hidden rounded-2xl object-cover`}>
      {image.imageData === null && (
        <div className="flex justify-center items-center">
          <h1 className="text-4xl font-bold font-mono tracking-tight text-zinc-100 sm:text-2xl">
            <span>Loading image</span>
            <span className="animate-ellipses-reveal">...</span>
          </h1>
        </div>
      )}
      {image.imageData !== null && (
        <div className="flex justify-center">
          <img
            src={image.imageData}
            alt=""
            id={`image-${listIndex}-${imageIndex}`}
            className="select-none"
            style={{ height: height, width: width, objectFit: 'cover' }}
          />
          <button
            type="button"
            className="absolute top-2 right-2 transform active:scale-95 transition-transform flex justify-center items-center opacity-60 text-white instrument-button-large bg-zinc-900 hover:bg-white hover:text-zinc-900"
            title="View details"
            onClick={(e) => {
              e.stopPropagation();
              modalProps.setOpenModal({
                isOpen: true,
                displayImages: [image],
                imageList,
              });
            }}
          >
            <MagnifyingGlassIcon className="w-full h-full" />
          </button>
          {isReference && (
            <div
              className="absolute top-2 left-2 transform active:scale-95 transition-transform flex justify-center items-center text-white instrument-button-large opacity-70 cursor-grab"
              title="Uploaded Image"
            >
              <ArrowUpTrayIcon className="h-8 w-8" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
