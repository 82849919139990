import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store, persistor } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';

import { EthereumClient, w3mConnectors } from '@web3modal/ethereum';
import { alchemyProvider } from '@wagmi/core/providers/alchemy';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { config } from './config/config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import '../node_modules/react-mask-editor/dist/style.css';

const container = document.getElementById('root')!;
const root = createRoot(container);

const chains = [mainnet];
const projectId = config.WALLET_CONNECT_PROJECT_ID!;
const alchemyKey = config.ALCHEMY_KEY!;

const { publicClient } = configureChains(chains, [
  alchemyProvider({ apiKey: alchemyKey }),
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});

const googleClientId: string = process.env.REACT_APP_GAUTH_CLIENT_ID as string;
const ethereumClient: EthereumClient = new EthereumClient(wagmiConfig, chains);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor} />
          <GoogleOAuthProvider clientId={googleClientId}>
            <WagmiConfig config={wagmiConfig}>
              <App />
            </WagmiConfig>
          </GoogleOAuthProvider>
          <Web3Modal
            projectId={projectId}
            ethereumClient={ethereumClient}
            themeVariables={{
              '--w3m-accent-color': '#6366f1',
            }}
          />
        </Provider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
